import { skipToken, useQuery } from "@tanstack/react-query";
import { PartnerDetails, PartnerPaymentDetails } from "types/partners";
import { apiGet, apiGetWithCustomErrorMessage } from "./api-client";
import { HttpError } from "../errors/HttpError";
import { LocalRoutes } from "../../constants/routes";

const fetchPartnerDetails = async (partnerId: string): Promise<PartnerDetails> => {
  const partnerDetails = await apiGetWithCustomErrorMessage(
    `/api/public/partners-api/v1/partners/${partnerId}/public`,
    (error) => {
      if (error.status === 404) {
        return (
          <>
            Oops! It looks like you're trying to access the Nexford application through an invalid link. If you're
            enrolling through a partner, please contact them for the correct link. To apply directly with Nexford,
            please visit <a href={LocalRoutes.HOME}>{window.location.origin}</a>
          </>
        );
      }

      return error.message;
    },
  );

  return {
    name: partnerDetails.name,
    partnerId,
    logoUploadedFileName: partnerDetails.logoUploadedFileName,
  } as PartnerDetails;
};

const fetchPartnerPaymentDetails = async (partnerId: string): Promise<PartnerPaymentDetails> =>
  apiGet(`/api/partners-api/v1/partners/${partnerId}/payment-url`);

const usePartnerDetails = (enabled: boolean, { partnerId }: { partnerId?: string | null } = {}) =>
  useQuery<PartnerDetails, HttpError<any>>({
    queryKey: ["partner-details-request", { partnerId }],
    queryFn: partnerId ? () => fetchPartnerDetails(partnerId) : skipToken,
    throwOnError: false,
    enabled: enabled && !!partnerId,
    staleTime: Infinity,
  });

const usePartnerPaymentDetails = (enabled: boolean, { partnerId }: { partnerId?: string | null } = {}) =>
  useQuery({
    queryKey: ["partner-payment-details-request", { partnerId }],
    queryFn: partnerId ? () => fetchPartnerPaymentDetails(partnerId) : skipToken,
    throwOnError: false,
    enabled: enabled && !!partnerId,
    staleTime: Infinity,
  });

export { usePartnerDetails, usePartnerPaymentDetails };
