import { LocalRoutes } from "constants/routes";
import { foundationProductCodes, learningPathProducts } from "constants/products";
import { RequirementType } from "types/registrations";

import "./application-navigation.scss";
import { NavSteps } from "./types";

const courseSteps: Array<NavSteps> = [
  {
    id: "personal",
    friendlyName: "Personal information",
    route: LocalRoutes.APPLICATION,
    requirements: [RequirementType.PersonalInfo, RequirementType.TermsAndConditions],
  },
  {
    id: "checkout",
    friendlyName: "Tuition fee checkout",
    route: LocalRoutes.TUITION_FEE_CHECKOUT,
    requirements: [RequirementType.TuitionFee, RequirementType.StartDate],
  },
];

const foundationSteps: Array<NavSteps> = [
  {
    id: "personal",
    friendlyName: "Personal information",
    route: LocalRoutes.APPLICATION,
    requirements: [RequirementType.PersonalInfo, RequirementType.TermsAndConditions],
  },
  {
    id: "checkout",
    friendlyName: "Tuition fee checkout",
    route: LocalRoutes.TUITION_FEE_CHECKOUT,
    requirements: [RequirementType.TuitionFee, RequirementType.StartDate],
  },
];

const certificateSteps: Array<NavSteps> = [
  {
    id: "education",
    friendlyName: "Previous education",
    route: LocalRoutes.APPLICATION_EDUCATION,
    requirements: [RequirementType.EducationInfo],
  },
  {
    id: "personal",
    friendlyName: "Personal information",
    route: LocalRoutes.APPLICATION,
    requirements: [
      RequirementType.PersonalInfo,
      RequirementType.EnglishProficiency,
      RequirementType.TermsAndConditions,
    ],
  },
  {
    id: "identity",
    friendlyName: "Identity documents",
    route: LocalRoutes.APPLICATION_IDENTITY,
    requirements: [RequirementType.PhotoIdentity, RequirementType.GovIdentity],
  },
  {
    id: "checkout",
    friendlyName: "Application fee checkout",
    route: LocalRoutes.APPLICATION_FEE_CHECKOUT,
    requirements: [RequirementType.ApplicationFee],
  },
];

const degreeSteps: Array<NavSteps> = [
  {
    id: "education",
    friendlyName: "Previous education",
    route: LocalRoutes.APPLICATION_EDUCATION,
    requirements: [RequirementType.EducationInfo, RequirementType.UnofficialTranscript],
  },
  {
    id: "personal",
    friendlyName: "Personal information",
    route: LocalRoutes.APPLICATION,
    requirements: [
      RequirementType.PersonalInfo,
      RequirementType.EnglishProficiency,
      RequirementType.TermsAndConditions,
    ],
  },
  {
    id: "identity",
    friendlyName: "Identity documents",
    route: LocalRoutes.APPLICATION_IDENTITY,
    requirements: [RequirementType.PhotoIdentity, RequirementType.GovIdentity],
  },
  {
    id: "checkout",
    friendlyName: "Application fee checkout",
    route: LocalRoutes.APPLICATION_FEE_CHECKOUT,
    requirements: [RequirementType.ApplicationFee],
  },
];

const mbaSteps: Array<NavSteps> = [
  {
    id: "education",
    friendlyName: "Previous education",
    route: LocalRoutes.APPLICATION_EDUCATION,
    requirements: [RequirementType.EducationInfo, RequirementType.UnofficialTranscript],
  },
  {
    id: "learning_path",
    friendlyName: "Degree learning path",
    route: LocalRoutes.APPLICATION_DEGREE_LEARNING_PATH,
    requirements: [RequirementType.EducationInfo],
  },
  {
    id: "personal",
    friendlyName: "Personal information",
    route: LocalRoutes.APPLICATION,
    requirements: [
      RequirementType.PersonalInfo,
      RequirementType.EnglishProficiency,
      RequirementType.TermsAndConditions,
    ],
  },
  {
    id: "identity",
    friendlyName: "Identity documents",
    route: LocalRoutes.APPLICATION_IDENTITY,
    requirements: [RequirementType.PhotoIdentity, RequirementType.GovIdentity],
  },
  {
    id: "checkout",
    friendlyName: "Application fee checkout",
    route: LocalRoutes.APPLICATION_FEE_CHECKOUT,
    requirements: [RequirementType.ApplicationFee],
  },
];

export const getNavigationSteps = (productType: string, productCode: string) => {
  switch (productType) {
    case "Course":
      return courseSteps;
    case "Certificate":
      if (foundationProductCodes.includes(productCode)) {
        return foundationSteps;
      } else {
        return certificateSteps;
      }
    case "Degree":
      if (learningPathProducts.includes(productCode)) {
        return mbaSteps;
      } else {
        return degreeSteps;
      }
    default:
      return [];
  }
};
