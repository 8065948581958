import React from "react";
import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

import { NEXFORD_LEARNER_STORIES, NEXFORD_YOUTUBE } from "constants/external-routes";

import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";

import "./success.scss";

/**
 * ApplyNXU Application Success
 */
const SuccessPage = () => (
  <CardPanel centre className="success-page__info-panel">
    <h1>Welcome to Nexford University</h1>
    <IonIcon icon={checkmarkCircleOutline} />
    <p>
      Congratulations! You're now ready to start your next-generation learning experience at Nexford. Anything is
      possible.
    </p>
    <NxuPrimaryButton href={process.env.REACT_APP_MYNXU_URL}>Head over to myNXU</NxuPrimaryButton>
    <p>We'll send you an email with your log-in details to get started.</p>
    <p>
      Why not also visit our{" "}
      <a href={NEXFORD_YOUTUBE} target="_blank" rel="noreferrer">
        YouTube channel
      </a>{" "}
      or listen to some other{" "}
      <a href={NEXFORD_LEARNER_STORIES} target="_blank" rel="noreferrer">
        learners' stories
      </a>{" "}
      about studying at Nexford.
    </p>
  </CardPanel>
);

export default SuccessPage;
