import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Options } from "react-select";

interface UsePlacesAutocompleteServiceResponse {
  placesAutocompleteService?: google.maps.places.AutocompleteService;
  sessionToken?: google.maps.places.AutocompleteSessionToken;
  refreshSessionToken: () => void;
}

/**
 * Initialise the places autocomplete from google maps js
 */
export const usePlacesAutocompleteService = (props: {
  onLoadFailed?: (error: Error) => void;
}): UsePlacesAutocompleteServiceResponse => {
  const { onLoadFailed } = props;

  const [placesAutocompleteService, setPlacesAutocompleteService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined);
  // For billing purposes, each session needs a unique token
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | undefined>(undefined);

  const initializeService = () => {
    if (!window.google) throw new Error("[react-google-places-autocomplete]: Google script not loaded");
    if (!window.google.maps) throw new Error("[react-google-places-autocomplete]: Google maps script not loaded");
    if (!window.google.maps.places)
      throw new Error("[react-google-places-autocomplete]: Google maps places script not loaded");

    setPlacesAutocompleteService(new window.google.maps.places.AutocompleteService());
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };

  const refreshSessionToken = () => {
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };

  useEffect(() => {
    if (!placesAutocompleteService || !sessionToken) {
      try {
        initializeService();
      } catch (error) {
        if (typeof onLoadFailed === "function") onLoadFailed(error as Error);
      }
    }
  }, [onLoadFailed, placesAutocompleteService, sessionToken]);

  return { placesAutocompleteService, sessionToken, refreshSessionToken };
};

/**
 * Construct the autocomplete request to be sent to google places
 */
const generateAutocompleteRequest = (
  searchValue: string,
  sessionToken?: google.maps.places.AutocompleteSessionToken,
): google.maps.places.AutocompletionRequest => {
  const request: google.maps.places.AutocompletionRequest = {
    input: searchValue,
    types: ["(cities)"],
  };
  if (sessionToken) request.sessionToken = sessionToken;

  // Request limiters go here
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  return request;
};

interface UseFetchPredictionsProps {
  placesAutocompleteService?: google.maps.places.AutocompleteService;
  sessionToken?: google.maps.places.AutocompleteSessionToken;
}

type PlacesCallbackOptions = (options: Options<any>) => void;
type UseFetchPredictionsResponse = (value: string, cb: PlacesCallbackOptions) => void;

/**
 * Call the places api for the autocomplete predictions, and convert the results to options that can be displayed in react-select
 */
export const useFetchPredictions = (props: UseFetchPredictionsProps): UseFetchPredictionsResponse => {
  const { placesAutocompleteService, sessionToken } = props;

  /* eslint-disable consistent-return */
  return useDebouncedCallback((value: string, cb: PlacesCallbackOptions): void => {
    if (!placesAutocompleteService) return cb([]);
    if (value.length < 3) return cb([]);

    placesAutocompleteService.getPlacePredictions(generateAutocompleteRequest(value, sessionToken), (results) =>
      cb(
        (results || []).map((prediction) => ({
          label: prediction.description,
          value: prediction.place_id,
        })),
      ),
    );
  }, 300);
};

/**
 * Call the places api for the location details
 */
export const getLocationDetailsById = (placeId: string): Promise<google.maps.GeocoderResult[]> => {
  const geocoder = new window.google.maps.Geocoder();
  const { OK } = window.google.maps.GeocoderStatus;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
      if (status !== OK) {
        return reject(status);
      }
      return resolve(results);
    });
  });
};

export const isValidLocation = (
  country: string | undefined,
  state: string | undefined,
  city: string | undefined,
): boolean => {
  if (!country) return false;
  if (!city) return false;
  if (country === "US" && !state) return false;
  return true;
};

export const getLocationError = (
  country: string | undefined,
  state: string | undefined,
  city: string | undefined,
): string => {
  let validationMessage = "The selected location is invalid and doesn't include a recognised";

  const missing = [];
  if (!city) missing.push("city");
  if (country === "US" && !state) missing.push("state");
  if (!country) missing.push("country");

  missing.forEach((value, index) => {
    validationMessage += ` ${value}`;
    if (index !== missing.length - 1) {
      validationMessage += ` or`;
    }
  });

  return validationMessage;
};
