import React, { useState } from "react";

import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

import { CurrencyProvider, FlutterwavePayee, FlutterwaveCallback, FlutterwaveConfig } from "types/payments";

import { NxuAlert, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";

interface FlutterwaveProps {
  onSubmit: (data: FlutterwaveCallback) => void;
  onCancel: () => void;
  submitting?: boolean;
  // Following props are required for flutterwave config initialisation
  flutterwaveConfig: FlutterwaveConfig;
  callbackId: string;
  payee: FlutterwavePayee;
  currencyProvider: CurrencyProvider;
  reference: string;
  initiator: string;
  couponCode: string | null;
}

/**
 * Payment interface component for Flutterwave
 */
export default function Flutterwave({
  onSubmit,
  onCancel,
  submitting,
  flutterwaveConfig,
  callbackId,
  payee,
  currencyProvider,
  reference,
  initiator,
  couponCode,
}: FlutterwaveProps) {
  const [paymentIsActive, setPaymentIsActive] = useState(false);

  // Declare the config necessary to initialise Flutterwave payment
  const config = {
    public_key: flutterwaveConfig.publicKey,
    tx_ref: callbackId,
    subaccounts: [],
    amount: currencyProvider.amount,
    currency: currencyProvider.currencyCode,
    payment_options: "card, mpesa",
    meta: {
      NXU_CallbackId: callbackId,
      LearnerID: payee.learnerId,
      ExchangeRate: currencyProvider.exchangeRate,
      Reference: reference,
      Initiator: initiator,
      NXU_AmountUSD: currencyProvider.amountUSD,
      Coupon: couponCode,
    },
    customer: {
      email: payee.email,
      phone_number: payee.phoneNumber || "",
      name: `${payee.firstName} ${payee.lastName}`,
    },
    customizations: {
      title: "Nexford University",
      logo: "https://mynxu.nexford.edu/logo.svg",
      description: "",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  if (!payee) {
    return <NxuAlert message="There was an error on loading the Flutterwave payment method"></NxuAlert>;
  }

  return (
    <NxuPrimaryButton
      onClick={() => {
        setPaymentIsActive(true);
        handleFlutterPayment({
          callback: (response: any) => {
            onSubmit(response);
            closePaymentModal();
            setPaymentIsActive(false);
          },
          onClose: () => {
            setPaymentIsActive(false);
            onCancel();
          },
        });
      }}
      disabled={submitting || paymentIsActive}
    >
      {submitting || paymentIsActive ? "In progress" : "Continue"}
    </NxuPrimaryButton>
  );
}
