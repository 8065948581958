import { useMutation, useQuery } from "@tanstack/react-query";
import {
  EnrollmentCodeVerificationResponse,
  LearnerPartnerAssociationResponse,
  SendVerificationCodeResponse,
  ValidateVerificationCodeResponse,
} from "types/authentication";
import { apiGet, apiPost } from "./api-client";

const fetchLearnerPartnerAssociation = async (
  learnerId?: string | null,
  partnerId?: string | null,
): Promise<LearnerPartnerAssociationResponse> =>
  apiGet(`/api/public/partners-codes-api/v1/${learnerId}/partners/${partnerId}/association`);

const useLearnerPartnerAssociation = (learnerId?: string | null, partnerId?: string | null, enabled?: boolean) =>
  useQuery({
    queryKey: ["learner-partner-association", partnerId],
    queryFn: () => fetchLearnerPartnerAssociation(learnerId, partnerId),
    throwOnError: false,
    enabled, // fetch automatically
    refetchOnWindowFocus: false,
    retry: 3,
  });

const useEmailVerification = (onSuccess?: (response: SendVerificationCodeResponse) => void) =>
  useMutation({
    mutationFn: (payload: { Email: string }): Promise<SendVerificationCodeResponse> =>
      apiPost("/api/auth/send-verification-code", payload),
    onSuccess,
  });

const useValidateVerification = (onSuccess?: (response: ValidateVerificationCodeResponse) => void) =>
  useMutation({
    mutationFn: (payload: { Email: string; Code: string }): Promise<ValidateVerificationCodeResponse> =>
      apiPost("/api/auth/validate-verification-code", payload),
    onSuccess,
  });

const useEnrollmentCodeVerification = (onSuccess?: (response: EnrollmentCodeVerificationResponse) => void) =>
  useMutation({
    mutationFn: (payload: { partnerId?: string; code?: string }): Promise<ValidateVerificationCodeResponse> =>
      apiPost(`/api/partners-codes-api/v1/self/partners/${payload.partnerId}/codes/${payload.code}/activate`, {}),
    onSuccess,
  });

export { useEmailVerification, useValidateVerification, useEnrollmentCodeVerification, useLearnerPartnerAssociation };
