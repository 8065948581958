import { NEXFORD_MAIL_BILLINGS } from "constants/external-routes";

export const BankTransferContent = (props: { learnerId: string }) => (
  <>
    <p>You can pay your application or tuition fees using a Bank Wire transfer:</p>
    <ul>
      <li data-testid="transfer-modal-account-name">
        <b>Account name:</b> Nexford University
      </li>
      <li data-testid="transfer-modal-account-number">
        <b>Account number:</b> 226001335778
      </li>
      <li data-testid="transfer-modal-routing-number">
        <b>Routing number:</b> 026009593
      </li>
      <li data-testid="transfer-modal-swift-code">
        <b>SWIFT:</b> BOFAUS3N
      </li>
      <li data-testid="transfer-modal-bank-address">
        <b>Bank Address: </b> Bank of America, 222 Broadway, New York, New York 10038
      </li>
      <li data-testid="transfer-modal-learnerId">
        <b>Reference: </b> {props.learnerId} <em>(your unique Learner ID)</em>
      </li>
    </ul>
    <p>
      After making payment via bank wire transfer, please share a screenshot of your transaction, and transaction
      hash/ID to{" "}
      <a href={`mailTo:${NEXFORD_MAIL_BILLINGS}`} target="_blank" rel="noreferrer">
        {NEXFORD_MAIL_BILLINGS}
      </a>{" "}
      from the same email address used in your Nexford application. When verified, your Nexford Advisor will contact you
      confirming your enrollment.
    </p>
  </>
);

export const PaypalContent = (props: { paymentComplete: boolean; learnerId: string }) => {
  if (props.paymentComplete) {
    return (
      <>
        <p>
          Once you have completed your transaction, it will take up to 48 hours to be verified. Send a screenshot of
          your transaction and transaction hash/ID to{" "}
          <a href={`mailTo:${NEXFORD_MAIL_BILLINGS}`} target="_blank" rel="noreferrer">
            {NEXFORD_MAIL_BILLINGS}
          </a>{" "}
          to process your payment faster. When verified, your Nexford Advisor will contact you confirming your
          enrollment.
        </p>
      </>
    );
  }

  return (
    <>
      <p>Go to PayPal to complete your transaction. You will need to enter the:</p>
      <ul>
        <li>Correct payment amount</li>
        <li>Your learner ID: {props.learnerId}</li>
        <li>What you are paying for (i.e. MBA Tuition 1x month) in the 'note' section during checkout</li>
      </ul>
    </>
  );
};
