import React, { useState } from "react";
import cx from "classnames";

import { IonButton, IonIcon, IonNote, IonPopover } from "@ionic/react";
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  cloudUploadOutline,
  informationCircleOutline,
} from "ionicons/icons";
import { DocumentItem } from "types/learner-profile";

import { useFileUploadService } from "utils/hooks/learner-identity";
import { NxuAlert } from "@nexford/nexford-ui-component-library";

import "./identity-document-form.scss";

export interface IdentityDocumentFormProps {
  setUploadResponse: (uploadResponse: { originalFileName: string; uploadedFileName: string } | undefined) => void;
  id: "idDocument" | "personalPhoto";
  exampleImg: string;
  friendlyName: string;
  placeholder: string;
  inProgress?: boolean;
  existingOriginalFileName?: string;
  currentDocument?: DocumentItem;
}

/**
 * Identity Documents Form for Degree Applicants
 */
const IdentityDocumentForm = ({
  setUploadResponse,
  id,
  exampleImg,
  placeholder,
  friendlyName,
  inProgress,
  existingOriginalFileName,
  currentDocument,
}: IdentityDocumentFormProps) => {
  const [currentFile, setCurrentFile] = useState<File>();
  const [currentFileName, setCurrentFileName] = useState<string | undefined>(
    currentDocument?.status !== "Failed" ? existingOriginalFileName : undefined,
  );
  const [currentFileError, setCurrentFileError] = useState<string>();

  const [fileUploadSubmitting, setFileUploadSubmitting] = useState(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(
    currentDocument?.status !== "Failed" && !!existingOriginalFileName,
  );
  const [uploadError, setUploadError] = useState<string>();

  const fileUploadService = useFileUploadService();

  const reset = () => {
    setCurrentFile(undefined);
    setCurrentFileName(undefined);
    setCurrentFileError(undefined);
    setFileUploadSuccess(false);
    setUploadError(undefined);
    setUploadResponse(undefined);
    const inputField = document.getElementById(`${id}-upload-field`);
    // @ts-ignore
    if (inputField) inputField.value = null;
  };

  // Validate file types & size for photo upload
  const isPhotoValid = (file: File) => {
    if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
      setCurrentFileError("You are uploading the wrong file type. Only .jpg or .png files are accepted.");
      return false;
    }

    if (file.size > 10485760) {
      setCurrentFileError("File size is larger than allowed size. The file must be less than 10 MB");
      return false;
    }
    setCurrentFileError(undefined);
    return true;
  };

  const getFileExtension = (file: File) => file.name.substring(file.name.lastIndexOf(".") + 1);

  // Upload the file to the api
  const submitForm = async (file: File) => {
    if (inProgress) return;
    if (currentFile) reset();
    setCurrentFileName(file.name);
    setCurrentFile(file);

    const isFileValid = file && isPhotoValid(file);

    if (isFileValid) {
      setFileUploadSubmitting(true);
      const uploadFileType = getFileExtension(file);

      fileUploadService.mutate(
        {
          payload: { file },
          fileExtension: uploadFileType,
        },
        {
          onSuccess(response) {
            setFileUploadSubmitting(false);
            setFileUploadSuccess(true);
            setUploadResponse({
              originalFileName: file.name,
              uploadedFileName: response.tempFileId,
            });
          },
          onError(e) {
            setUploadResponse(undefined);
            setFileUploadSubmitting(false);
            setUploadError(e.message);
          },
        },
      );
    }
  };

  // Trigger the file upload UI to open
  const openFileUpload = (e: React.MouseEvent) => {
    e.preventDefault();
    const inputField = document.getElementById(`${id}-upload-field`);
    inputField?.click();
  };

  const tooltipMessage = () => {
    switch (id) {
      case "idDocument":
        return <p>Upload your ID.</p>;
      default:
        return (
          <p>
            Make sure we can clearly see your full face. Don't wear sunglasses, hats, or anything else that obstructs
            your face. Do not edit or crop the image.
          </p>
        );
    }
  };

  return (
    <form data-testid={`upload-document-form-${id}`} className="application-form identity-document__form">
      <img src={exampleImg} alt={`Example of ${friendlyName}`} />
      {uploadError && <NxuAlert fullWidth message={uploadError} />}
      <div
        className={cx(
          "identity-document__field-wrapper",
          !!currentFileError && "identity-document__field-wrapper--error",
        )}
      >
        <input
          data-testid={`${id}-upload-field`}
          id={`${id}-upload-field`}
          type="file"
          hidden
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => {
            const file = e.target.files && e.target.files[0];
            if (file) submitForm(file);
          }}
        />
        <div className="identity-document__field-row">
          <div className="identity-document__field-row__cta">
            <IonButton
              id={`${id}-tooltip-trigger`}
              shape="round"
              color="dark"
              fill="clear"
              aria-label={`Open tooltip for ${friendlyName}`}
            >
              <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
            </IonButton>
          </div>

          <button
            disabled={fileUploadSubmitting || inProgress}
            className={cx(
              "identity-document__upload-btn",
              !!currentFileName && "identity-document__upload-btn--complete",
            )}
            onClick={(e) => openFileUpload(e)}
          >
            <span>{currentFileName || placeholder}</span>
            {!uploadError && !currentFileError && !fileUploadSuccess && (
              <IonIcon slot="icon-only" icon={cloudUploadOutline}></IonIcon>
            )}
            {fileUploadSuccess && <IonIcon color="success" slot="icon-only" icon={checkmarkCircleOutline}></IonIcon>}
            {(!!uploadError || !!currentFileError) && (
              <IonIcon color="danger" slot="icon-only" icon={alertCircleOutline}></IonIcon>
            )}
          </button>

          <div className="identity-document__field-row__cta">
            <IonButton
              shape="round"
              color="dark"
              fill="clear"
              aria-label={`Clear the uploaded file for ${friendlyName}`}
              disabled={fileUploadSubmitting || inProgress || !currentFileName}
              onClick={() => {
                reset();
              }}
            >
              <IonIcon slot="icon-only" icon={closeCircleOutline}></IonIcon>
            </IonButton>
          </div>
        </div>
        {!!currentFileError && <IonNote color="danger">{currentFileError}</IonNote>}
        {!fileUploadSuccess && currentDocument?.status === "Failed" && (
          <div className="identity-document__field-row identity-document__field-row--comment">
            <IonNote color="danger">{`Your document was rejected. ${currentDocument.comment}`}</IonNote>
          </div>
        )}
        {currentDocument?.status === "Passed" && (
          <div className="identity-document__field-row identity-document__field-row--comment">
            <IonNote color="success">Your document was verified.</IonNote>
          </div>
        )}
      </div>
      <IonPopover
        className="upload-field-tooltip"
        trigger={`${id}-tooltip-trigger`}
        triggerAction="click"
        side="bottom"
        alignment="start"
      >
        {tooltipMessage()}
      </IonPopover>
    </form>
  );
};

export default IdentityDocumentForm;
