import { useQuery } from "@tanstack/react-query";
import { Product, ProductDetails, ProductsListItem } from "types/admissions";
import { SelectItem } from "types/forms";
import { apiGet, apiGetWithCustomErrorMessage } from "./api-client";
import { HttpError } from "../errors/HttpError";
import { LocalRoutes } from "../../constants/routes";

export const admissionErrMsg = {
  fetchAdmissionStatus:
    "Uh-oh! There was an error on getting your application details. Please refresh and try again or contact support.",
};

const fetchProgramDetails = async (productType?: string, productCode?: string): Promise<Product> =>
  apiGetWithCustomErrorMessage(`/api/requirements/versions/latest/products/${productType}/${productCode}`, (error) => {
    if (error.status === 404) {
      return (
        <>
          Oops! It looks like you're trying to access the Nexford application through an invalid link. If you're
          enrolling through a partner, please contact them for the correct link. To apply directly with Nexford, please
          visit <a href={LocalRoutes.HOME}>{window.location.origin}</a>
        </>
      );
    }

    return `There was an error on loading your selected program: ${error.message}.`;
  });

const useProgramDetails = (productType?: string | null, productCode?: string | null) =>
  useQuery<Product, HttpError<any>>({
    queryKey: ["program-details-request", productType, productCode],
    queryFn: () => fetchProgramDetails(productType || "", productCode || ""),
    throwOnError: false,
    enabled: !!productType && !!productCode,
    staleTime: Infinity,
  });

const fetchFullProgramDetails = async (productCode?: string): Promise<ProductDetails> =>
  apiGet(`/api/public/applynxuaggregator/course/${productCode}/info`);

const useFullProgramDetails = (isCourse?: boolean, productCode?: string | null) =>
  useQuery({
    queryKey: ["full-program-details-request", productCode],
    queryFn: () => fetchFullProgramDetails(productCode || ""),
    throwOnError: false,
    enabled: !!isCourse && !!productCode,
    staleTime: Infinity,
  });

const fetchAllProductsList = async (partnerId?: string): Promise<Array<ProductsListItem>> => {
  let endpoint = "/api/public/applynxuaggregator/catalog";
  if (partnerId) {
    endpoint += `?partnerId=${partnerId}`;
  }
  return apiGet(endpoint);
};

const useAllProductsList = (enabled: boolean, { partnerId }: { partnerId?: string } = {}) =>
  useQuery({
    queryKey: ["all-products-request", { partnerId }],
    queryFn: () => fetchAllProductsList(partnerId),
    throwOnError: false,
    enabled,
    staleTime: Infinity,
  });

const fetchNationalities = async (): Promise<Array<SelectItem>> => {
  const response = apiGet("/api/location/nationalities").then((resp) =>
    /* eslint-disable no-nested-ternary */
    resp.sort((a: { label: string; value: string }, b: { label: string; value: string }) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0,
    ),
  );

  return response;
};

const useNationalities = (enabled: boolean) =>
  useQuery({
    queryKey: ["nationalities-request"],
    queryFn: fetchNationalities,
    throwOnError: false,
    staleTime: Infinity,
    enabled,
  });

const fetchProgramStartDates = async (): Promise<Array<string>> =>
  apiGet("/api/admission/startDate-options/", admissionErrMsg.fetchAdmissionStatus);

const useProgramStartDates = (token: string | null) =>
  useQuery({
    queryKey: ["program-admission-start-dates"],
    queryFn: fetchProgramStartDates,
    throwOnError: false,
    enabled: !!token,
    refetchOnWindowFocus: false,
  });

export { useProgramDetails, useFullProgramDetails, useAllProductsList, useNationalities, useProgramStartDates };
