export enum EnvironmentPrefix {
  production = "nxupro",
  qa = "nxuqa",
  local = "local",
}

// The "Test environment" will restrict test features to qa and local
export const testEnvironment =
  process.env.REACT_APP_DEPLOYMENT_ENV === EnvironmentPrefix.qa ||
  process.env.REACT_APP_DEPLOYMENT_ENV === EnvironmentPrefix.local;
// Test flag can be managed by users enabling or disabling the test flag from the switch in header.tsx
export const localTestFlag = localStorage.getItem("qaTestFlag");

export const isTestEnv = testEnvironment && localTestFlag === "true";
