import { Input, InputProps } from "components/atom/input/input";
import React from "react";
import { FieldValues } from "react-hook-form";

type Props<T extends FieldValues> = {} & InputProps<T>;

export const VerificationCodeInput = <T extends FieldValues>(props: Props<T>) => (
  <div>
    <h4 className="code-verification-form__title">
      We've sent a verification code to your email. Please copy and paste it into the field below or hit the pencil icon
      to provide a different email.
    </h4>
    <Input data-testid="code-verification-input" fill="outline" placeholder="Verification Code" {...props} />
  </div>
);
