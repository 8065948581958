export class HttpError<T> extends Error {
  statusCode: number;

  data: T;

  constructor(message: string, statusCode: number, data: T) {
    super(message, { cause: statusCode });

    this.message = message;
    this.statusCode = statusCode;
    this.data = data;
  }
}
