import React, { ReactElement } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { IonNote, IonSpinner } from "@ionic/react";
import { ControllerFieldState } from "react-hook-form";
import AsyncSelect from "react-select/async";
import cx from "classnames";

import config from "config";
import { NxuAlert } from "@nexford/nexford-ui-component-library";
import {
  useFetchPredictions,
  usePlacesAutocompleteService,
  getLocationDetailsById,
} from "utils/service/google-maps-service";

export interface LocationSelectItem {
  label: string; // Will use the description returned by google places
  value: string; // Will use the place_id returned by google places
}

export interface NxuLocationFieldProps {
  // Auto complete event props
  onAutocompleteLoadFailed: (error: Error) => void;
  onGeocodeLookupFailed: () => void;
  // react-select props
  fieldName: string;
  defaultValue: string;
  onChange: (...event: any[]) => void;
  placeholder: string;
  fieldState: ControllerFieldState;
  isDisabled: boolean;
}

/**
 * Location input field that will return the place lookup and details from google places
 */
export const NxuLocationInput = (props: NxuLocationFieldProps) => {
  const { fieldName, onChange, defaultValue, placeholder, fieldState, isDisabled } = props;

  // Initialise the autocomplete service
  const { placesAutocompleteService, sessionToken, refreshSessionToken } = usePlacesAutocompleteService({
    // Error handling if the autocomplete fails to load
    onLoadFailed: props.onAutocompleteLoadFailed,
  });

  // Call the autocomplete and get the select options back
  const fetchPlaceOptions = useFetchPredictions({
    placesAutocompleteService,
    sessionToken,
  });

  // On selecting an option, get the place details and return them to the parent
  const handleSelection = async (selectedOption?: LocationSelectItem) => {
    if (selectedOption) {
      await getLocationDetailsById(selectedOption.value)
        .then((res) => {
          refreshSessionToken();
          onChange(selectedOption, res);
        })
        .catch((err) => {
          console.warn(err);
          props.onGeocodeLookupFailed();
        });
    } else {
      onChange(null, null);
    }
  };

  return (
    <div data-testid="nxu-location-field">
      <AsyncSelect
        form={fieldName}
        loadOptions={fetchPlaceOptions}
        // @ts-ignore
        onChange={(option) => handleSelection(option)}
        defaultInputValue={defaultValue}
        className={cx("nxu-select", fieldState.error && "nxu-select--error")}
        classNamePrefix="nxu-select"
        placeholder={placeholder}
        isDisabled={isDisabled}
        maxMenuHeight={180}
        menuShouldScrollIntoView
        isClearable
        backspaceRemovesValue
        blurInputOnSelect
        noOptionsMessage={(e) => (e.inputValue ? "No matching location found" : "Start typing to search...")}
      />
      {fieldState.error && <IonNote className="nxu-select__error-note">{fieldState.error?.message}</IonNote>}
    </div>
  );
};

/**
 * Loading & Error handler for the google places library
 */
const MapsWrapperRender = (status: Status): ReactElement => {
  if (status === Status.FAILURE)
    return <NxuAlert message="There was an error on loading the location service. Please refresh the page." />;
  return <IonSpinner />;
};

/**
 * Input field wrapper initialising the google places library
 */
const NxuLocationField = (props: NxuLocationFieldProps) => (
  <Wrapper apiKey={config.googleMaps.id} libraries={["places"]} render={MapsWrapperRender}>
    <NxuLocationInput {...props} />
  </Wrapper>
);

export default NxuLocationField;
