import React from "react";
import cx from "classnames";
import PhoneInput from "react-phone-number-input/react-hook-form";

import { CountryCode } from "libphonenumber-js";
import { FieldError } from "react-hook-form";

import "react-phone-number-input/style.css";
import "./nxu-phone-input.scss";
import { IonNote } from "@ionic/react";

export interface NxuPhoneInputProps {
  name: string;
  control: any;
  rules: any;
  fieldId: string;
  defaultCountry: CountryCode;
  isDisabled?: boolean;
  errors?: FieldError;
}

const NxuPhoneInput = (props: NxuPhoneInputProps) => {
  const { fieldId, name, control, rules, defaultCountry, isDisabled, errors } = props;

  return (
    <div className={cx("nxu-phone-input__wrapper", errors && "nxu-phone-input__wrapper--error")} data-testid={fieldId}>
      <div>
        <PhoneInput
          name={name}
          control={control}
          rules={rules}
          disabled={isDisabled}
          defaultCountry={defaultCountry}
          placeholder="Phone Number"
        />
      </div>
      {!!errors && <IonNote className="application-form__error-note">{errors.message}</IonNote>}
    </div>
  );
};

export default NxuPhoneInput;
