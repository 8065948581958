import { IonInput } from "@ionic/react";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";
import "./input.scss";
import cn from "classnames";

export type InputProps<T extends FieldValues> = {} & UseControllerProps<T> & React.ComponentProps<typeof IonInput>;

export const Input = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  disabled,
  rules,
  shouldUnregister,
  type = "text",
  fill = "outline",
  placeholder,
  label,
  className,
  ...props
}: InputProps<T>) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    disabled,
    rules,
    shouldUnregister,
  });

  return (
    <div className={cn("input__container", className)}>
      {label && <div className="input__label">{label}</div>}
      <IonInput
        aria-label={placeholder}
        placeholder={placeholder}
        name={name}
        {...props}
        value={field.value}
        onChange={field.onChange}
        onIonInput={field.onChange}
        onIonBlur={field.onBlur}
        disabled={disabled}
        type={type}
        fill={fill}
        errorText={fieldState.error?.message}
        className={fieldState.error ? "ion-touched ion-invalid" : ""}
      />
    </div>
  );
};
