import React, { useEffect } from "react";
import cx from "classnames";
import { IonButton, IonIcon, IonInput } from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { NxuAlert, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";

import { StorageKeys } from "constants/storage-keys";
import { useEmailVerification } from "utils/hooks/authentication";

import "./email-verification-form.scss";

// Email Form validation schema
const emailVerificationFormSchema = yup.object({
  Email: yup.string().email().required(),
});

export interface EmailVerificationFormProps {
  storedEmail: string;
  emailSubmitted: boolean;
  setEmailToVerify: (email: string) => void;
  setEmailSubmitted: (isTrue: boolean) => void;
  setLearnerId: (learnerId: string) => void;
  parentResetEvent?: () => void;
  parentDisabledEvent?: boolean;
}

/**
 * New applicants must verify their email before proceeding
 */
const EmailVerificationForm = (props: EmailVerificationFormProps) => {
  const {
    setEmailToVerify,
    setEmailSubmitted,
    setLearnerId,
    storedEmail,
    emailSubmitted,
    parentResetEvent,
    parentDisabledEvent,
  } = props;

  const sendEmailToVerification = useEmailVerification((r) => {
    sessionStorage.setItem(StorageKeys.LEARNER_ID, r.UserId);
    setLearnerId(r.UserId);
  });

  const verificationInProgress = emailSubmitted;

  const newEmailVerificationForm = useForm({
    defaultValues: {
      Email: storedEmail || "",
    },
    resolver: yupResolver(emailVerificationFormSchema),
  });
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    setValue,
  } = newEmailVerificationForm;

  const handleEmailVerificationSubmit: SubmitHandler<any> = async (formValues) => {
    // Function shouldn't be reachable without valid form values, but adding an escape just in case
    if (!isValid || sendEmailToVerification.isPending) return;
    sendEmailToVerification.mutate(formValues);
  };

  const emailEdit = () => {
    setEmailSubmitted(false);
    if (parentResetEvent) parentResetEvent();
  };

  useEffect(() => {
    if (storedEmail && emailSubmitted) {
      setValue("Email", storedEmail);
    }
  }, [setValue, storedEmail, emailSubmitted]);

  useEffect(() => {
    if (sendEmailToVerification.isSuccess) {
      setEmailToVerify(getValues("Email"));
      sessionStorage.setItem(StorageKeys.APPLICATION_EMAIL, getValues("Email"));
      setEmailSubmitted(true);
    }
  }, [sendEmailToVerification.isSuccess, getValues, setEmailSubmitted, setEmailToVerify]);

  return (
    <div>
      <form
        className="email-verification-form"
        onSubmit={handleSubmit(handleEmailVerificationSubmit)}
        data-testid="email-verification-form"
      >
        <h4
          aria-hidden={emailSubmitted}
          className={cx("email-verification-form__title", emailSubmitted && "email-verification-form__title--hidden")}
        >
          Get started by telling us your email address
        </h4>

        {sendEmailToVerification.isError && (
          <NxuAlert
            message={`We're having difficulty getting your enrollment started: ${sendEmailToVerification.error}. Please refresh the page and if the problem persists,
          contact support for help.`}
          />
        )}

        <div className="email-verification-form__row">
          <Controller
            control={control}
            name="Email"
            render={({ field }) => (
              <IonInput
                data-testid="email-verification-input"
                fill="outline"
                value={field.value}
                disabled={parentDisabledEvent || verificationInProgress || sendEmailToVerification.isPending}
                onIonInput={field.onChange}
                onIonBlur={field.onBlur}
                type="email"
                placeholder="Email"
                aria-label="Email"
                errorText={errors.Email?.message}
                className={errors.Email ? "ion-touched ion-invalid" : ""}
              />
            )}
          />
          {verificationInProgress && (
            <IonButton
              shape="round"
              size="small"
              aria-label="Change email address"
              onClick={emailEdit}
              type="button"
              disabled={parentDisabledEvent || !verificationInProgress}
            >
              <IonIcon slot="icon-only" icon={pencilOutline}></IonIcon>
            </IonButton>
          )}
        </div>

        {!verificationInProgress && (
          <NxuPrimaryButton
            type="submit"
            expand="block"
            disabled={parentDisabledEvent || verificationInProgress || sendEmailToVerification.isPending}
          >
            {sendEmailToVerification.isPending ? "Submitting" : "Submit"}
          </NxuPrimaryButton>
        )}
      </form>
    </div>
  );
};

export default EmailVerificationForm;
