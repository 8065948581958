import { useQuery } from "@tanstack/react-query";

import { apiGet } from "./api-client";

const fetchPrivacyPolicy = async (): Promise<{ Content: string }> => apiGet(`/api/agreement/privacy-policy`);

const usePrivacyPolicy = (enableRequest: boolean) =>
  useQuery({
    queryKey: ["agreement-privacy-policy-request"],
    queryFn: () => fetchPrivacyPolicy(),
    throwOnError: false,
    enabled: enableRequest,
    staleTime: Infinity,
  });

const fetchTermsConditions = async (productProvider: string): Promise<{ Content: string; Version: number | null }> =>
  apiGet(`/api/agreement/terms-conditions/${productProvider}`);

const useTermsConditions = (productProvider: string, enableRequest: boolean) =>
  useQuery({
    queryKey: ["agreement-terms-conditions-request"],
    queryFn: () => fetchTermsConditions(productProvider),
    throwOnError: false,
    enabled: enableRequest,
    staleTime: Infinity,
  });

export { usePrivacyPolicy, useTermsConditions };
