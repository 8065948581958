import React from "react";
import { Link } from "react-router-dom";
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonToggle } from "@ionic/react";

// Consts
import { LocalRoutes } from "constants/routes";
import { testEnvironment } from "constants/feature-flags";
import { ReactComponent as LogoImg } from "assets/img/logos/logo-titlebar.svg";

// Styles
import "./header.scss";

const Header = () => {
  const storedTestFlag = localStorage.getItem("qaTestFlag");

  const setTestFlag = (e: any) => {
    if (testEnvironment) {
      localStorage.setItem("qaTestFlag", e.detail.checked);
      window.location.reload();
    }
  };

  return (
    <IonHeader mode="md" data-testid="nxu-header">
      <IonToolbar className="nxu-header__titlebar">
        <IonTitle>
          <Link to={LocalRoutes.HOME}>
            <LogoImg aria-label="Nexford University ApplyNXU" className="header-logo" />
          </Link>
        </IonTitle>
        <IonButtons slot="end" id="test-flag-mask">
          {testEnvironment && (
            <IonToggle labelPlacement="start" checked={storedTestFlag === "true"} onIonChange={(e) => setTestFlag(e)}>
              QA Test Mode
            </IonToggle>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
