export const LocalRoutes = {
  HOME: "/",
  APPLICATION: "/application",
  APPLICATION_DEGREE_LEARNING_PATH: "/application/degree-learning-path",
  APPLICATION_EDUCATION: "/application/education",
  APPLICATION_IDENTITY: "/application/identity",
  TUITION_FEE_CHECKOUT: "/application/tuition-checkout",
  APPLICATION_FEE_CHECKOUT: "/application/checkout",
  APPLICATION_SUCCESS: "/application/success",
  APPLICATION_COMPLETE: "/application/complete",
  APPLICATION_PENDING: "/application/pending",
  CHECK_PARTNER_APPLICATION_FEE: "/application/check-partner-application-fee",
  CHECK_PARTNER_TUITION_FEE: "/application/check-partner-tuition-fee",
};
