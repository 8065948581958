import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useStripeConfig } from "utils/hooks/payments";

export interface StripeContextType {
  loading: boolean;
  stripeApiKey: string;
  stripeLoadingError?: string;
}

const initialStripeState: StripeContextType = {
  loading: false,
  stripeApiKey: "",
  stripeLoadingError: "",
};

export interface StripeProviderProps {
  children?: React.ReactNode;
  providedState?: StripeContextType;
}

/**
 * Get and Store the Stripe API key from the server for use throughout Campus UI
 */
const StripeContext = React.createContext<StripeContextType>({} as StripeContextType);

export function StripeProvider(props: StripeProviderProps) {
  const { children, providedState } = props;

  const state = useMemo(() => ({ ...initialStripeState, ...providedState }), [providedState]);
  const [loading, setLoading] = useState<boolean>(state.loading);
  const [stripeApiKey, setStripeApiKey] = useState<string>(state.stripeApiKey);
  const [stripeLoadingError, setStripeLoadingError] = useState<string | undefined>(state.stripeLoadingError);

  // Get tuition fee, will fetch when start date is set
  const {
    data: stripeConfig,
    isLoading: stripeConfigIsLoading,
    error: stripeConfigError,
    refetch: stripeConfigRequestFetch,
  } = useStripeConfig();

  const loadStripeApiKey = useCallback(async () => {
    await stripeConfigRequestFetch();
  }, [stripeConfigRequestFetch]);

  useEffect(() => {
    if (stripeConfig) setStripeApiKey(stripeConfig?.PublishableKey);
  }, [stripeConfig]);

  useEffect(() => {
    setStripeLoadingError(stripeConfigError?.message);
  }, [stripeConfigError]);

  useEffect(() => {
    setLoading(stripeConfigIsLoading);
  }, [stripeConfigIsLoading]);

  useEffect(() => {
    if (!loading && !stripeApiKey && !stripeConfigError) {
      loadStripeApiKey();
    }
  }, [loadStripeApiKey, loading, stripeApiKey, stripeConfigError]);

  const value = {
    loading,
    stripeApiKey,
    stripeLoadingError,
  };

  return <StripeContext.Provider value={value}>{children}</StripeContext.Provider>;
}

export const useStripeContext = () => useContext(StripeContext);
