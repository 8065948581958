import React from "react";
import { IonButton, IonIcon, IonInput, IonSpinner } from "@ionic/react";
import { walletOutline } from "ionicons/icons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import CardPanel from "components/atom/card-panel";

import "./partner-payment-form.scss";
import { usePartnerPaymentDetails } from "utils/hooks/partners";
import { useConfirmPartnerPaymentCode } from "utils/hooks/payments";
import { NxuAlert, NxuComponentLoading } from "@nexford/nexford-ui-component-library";

const FormSchema = yup.object().shape({
  paymentCode: yup.string().required("Payment confirmation code is required"),
});

export interface PartnerPaymentFormProps {
  partnerId: string;
  learnerCountry: string;
  productType: string;
  productCode: string;
  startMonth: number;
  amount: number;
  isDegreeTuitionFee?: boolean;
  onSuccess: () => void;
}

/**
 * ApplyNXU Application Checkout
 */
export const PartnerPaymentForm = ({
  partnerId,
  productType,
  productCode,
  learnerCountry,
  startMonth,
  amount,
  isDegreeTuitionFee,
  onSuccess,
}: PartnerPaymentFormProps) => {
  const confirmCodeForm = useForm({
    defaultValues: {
      paymentCode: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(FormSchema),
  });

  const {
    formState: { isValid },

    control,
    handleSubmit,
    setValue,
    watch,
  } = confirmCodeForm;

  const paymentCode = watch("paymentCode");

  const { mutate: confirmPayment, isError, isPending } = useConfirmPartnerPaymentCode({ onSuccess });

  const handleConfirmSubmit: SubmitHandler<any> = async () => {
    confirmPayment({ productType, productCode, paymentCode, learnerCountry, startMonth, amount });
  };

  const { data: partnerDetails, isLoading: isPartnerDetailsLoading } = usePartnerPaymentDetails(true, { partnerId });

  return (
    <div className="checkout-page__partner-payment">
      <form onSubmit={handleSubmit(handleConfirmSubmit)} data-testid="payment-form">
        <CardPanel testId="checkout-page__partner-payment">
          <div className="payment-form">
            {isDegreeTuitionFee && <h2>Payment</h2>}

            {isPartnerDetailsLoading ? (
              <NxuComponentLoading />
            ) : (
              <div className="payment-form__row">
                <div className="payment-form__row--left">
                  <div className="payment-form__label">
                    {/* <span aria-label="Enter payment code" className="nxu-icon nxu-icon-Voucher_icon_payment" /> */}
                    <IonIcon icon={walletOutline} />
                    <span>Tuition Payment</span>
                  </div>
                </div>
                <div className="payment-form__row--right">
                  <div data-testid="tuition-fee-msg">
                    <p className="message">
                      <strong>Your tuition payments will be collected by our partner, {partnerDetails?.name}.</strong>
                    </p>
                    <p className="message">
                      To enroll, pay the first tuition payment and enter the payment confirmation code below
                    </p>

                    <div className="payment-form__pay-partner">
                      <a href={partnerDetails?.paymentUrl} target="_blank" rel="noreferrer">
                        Pay {partnerDetails?.name}
                      </a>
                    </div>
                  </div>

                  <Controller
                    control={control}
                    name="paymentCode"
                    render={({ field }) => (
                      <div>
                        <strong>Payment Confirmation Code</strong>
                        <div className="payment-form__payment-code-container">
                          <IonInput
                            data-testid="payment-code-input"
                            fill="outline"
                            disabled={isPending}
                            onIonInput={(e) => {
                              setValue("paymentCode", e.detail.value || "");
                              field.onChange(e);
                            }}
                            onIonBlur={field.onBlur}
                            type="text"
                            placeholder="Enter code"
                            aria-label="Enter payment code"
                            errorText="This payment code is invalid"
                            className={isError ? "payment-code_input ion-touched ion-invalid" : "payment-code_input"}
                            value={field.value}
                          />
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </CardPanel>

        {!isPartnerDetailsLoading && isError && (
          <NxuAlert message={`Invalid code. Please try again or contact ${partnerDetails?.name}`} />
        )}

        {!isPartnerDetailsLoading && (
          <div className="save-button">
            <IonButton
              size="large"
              disabled={!isValid || isPending}
              type="submit"
              data-testid="checkout-page__partner-payment-form__save-button"
            >
              {isPending ? <IonSpinner /> : <>Enroll</>}
            </IonButton>
          </div>
        )}
      </form>
    </div>
  );
};

export default PartnerPaymentForm;
