import React, { useState } from "react";

import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import ProductsListModal from "components/molecule/products-list-modal";

import "./catalog.scss";

type Props = {
  partnerId?: string;
};

// Catalog section to be displayed beneath verification when no product is specified in the URL
const Catalog = ({ partnerId }: Props) => {
  const [isChangeProductModalOpen, setIsChangeProductModalOpen] = useState(false);

  const closeProductsListModal = () => {
    setIsChangeProductModalOpen(false);
  };

  return (
    <div className="catalog-section">
      <h2>New to Nexford?</h2>
      <p>Head over to our catalog to select a program.</p>
      <NxuPrimaryButton onClick={() => setIsChangeProductModalOpen(true)}>Select Program</NxuPrimaryButton>
      <ProductsListModal
        noCurrentProduct={true}
        isOpen={isChangeProductModalOpen}
        closeModal={closeProductsListModal}
        partnerId={partnerId}
      />
    </div>
  );
};

export default Catalog;
