export const NEXFORD_PRIVACY_POLICY = `${process.env.REACT_APP_WWW_URL}/privacy-policy`;
export const NEXFORD_LEARNER_STORIES = `${process.env.REACT_APP_WWW_URL}/learner-stories`;
export const NEXFORD_PAGE_PAYMENT = `${process.env.REACT_APP_WWW_URL}/payment`;
export const NEXFORD_HOME_PROGRAMS = `${process.env.REACT_APP_WWW_URL}/programs-overview`;

export const PAYPAL_URL = "http://paypal.me/nexford";

export const NEXFORD_YOUTUBE = "https://www.youtube.com/c/nexforduniversity";

export const NEXFORD_MAIL_HELP = "help@nexford.org";
export const NEXFORD_MAIL_BILLINGS = "billings@nexford.org";
export const NEXFORD_MAIL_SUCCESS = "success@nexford.org";
export const NEXFORD_MAIL_ADMISSION = "admissions@nexford.org";

export const NEXFORD_CALENDAR_SUCCESS =
  "https://outlook.office365.com/owa/calendar/LearnerSuccess@nexford.org/bookings/";

export const NEXFORD_MESSENGER = "http://m.me/nexford";

export const NEXFORD_WHATSAPP = "https://api.whatsapp.com/send/?phone=12022802049";
export const NEXFORD_WHATSAPP_ADMISSION = "https://api.whatsapp.com/send/?phone=12022802049";
export const NEXFORD_WHATSAPP_SUCCESS = "https://api.whatsapp.com/send/?phone=12026601426";
export const NEXFORD_WHATSAPP_BILLINGS = "https://api.whatsapp.com/send/?phone=12063178160";
