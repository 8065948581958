import GetInTouch from "components/atom/get-in-touch";

// Set of messages to be displayed for enrolled or rejected applicants

export const enrolledMsg = (
  <>
    <p>
      It looks like you're already enrolled with Nexford University! You can view your program and learner profile on{" "}
      <a href={process.env.REACT_APP_MYNXU_URL}>myNXU</a>.
    </p>
    <GetInTouch type="admission">
      If you have any questions, or would like to change programs, please contact us.
    </GetInTouch>
  </>
);

export const rejectedMsg = (
  <>
    <p>We've noticed that there is a previous application linked to your email address.</p>
    <GetInTouch type="admission">
      Please contact admissions@nexford.org directly if you would like to continue with your application.
    </GetInTouch>
  </>
);
