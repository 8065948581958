import React from "react";
import { IonNote } from "@ionic/react";
import { ControllerFieldState } from "react-hook-form";
import Select, { InputProps, components } from "react-select";
import cx from "classnames";

import "./nxu-select.scss";
import { SelectItem } from "types/forms";

export interface NxuSelectProps {
  selectedValue: string | number | undefined;
  onChange: (...event: any[]) => void;
  options: Array<SelectItem>;
  defaultOptionIndex?: number;
  placeholder: string;
  ariaLabel?: string;
  classNamePrefix?: string;
  field?: any;
  fieldState?: ControllerFieldState;
  isDisabled: boolean;
}

// Standardised NXU select component to be used with a form
const NxuSelect = (props: NxuSelectProps) => {
  const {
    selectedValue,
    onChange,
    options,
    defaultOptionIndex,
    placeholder,
    ariaLabel,
    classNamePrefix,
    field,
    fieldState,
    isDisabled,
  } = props;

  // React-select has an ongoing issue with how it interacts with Chromium autofill, and Chromium autofill has some very odd requirements
  // https://github.com/JedWatson/react-select/issues/3500
  // https://lightrun.com/solutions/jedwatson-react-select-disable-chrome-autofill-allowing-autocompletenew-password-to-be-passed-into-the-input-component-p/
  // So we're left with this as a solution for now
  const Input = (inputProps: InputProps) => <components.Input {...inputProps} autoComplete="new-password" />;

  // Allow the select to scroll to a default option when menu is opened
  const scrollToDefaultOption = () => {
    if (!selectedValue && defaultOptionIndex && classNamePrefix) {
      setTimeout(() => {
        const defaultOption = document.getElementsByClassName(`${classNamePrefix} nxu-select__option`)[
          defaultOptionIndex
        ];
        if (defaultOption) defaultOption.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
      }, 10);
    }
  };

  return (
    <div>
      <Select
        {...field}
        components={{ Input }}
        className={cx("nxu-select", fieldState?.error && "nxu-select--error")}
        classNamePrefix={classNamePrefix ? `${classNamePrefix} nxu-select` : `nxu-select`}
        options={options}
        onMenuOpen={() => scrollToDefaultOption()}
        onChange={(option) => onChange(option)}
        // eslint-disable-next-line eqeqeq
        value={selectedValue ? options?.find((option) => option.value == selectedValue) : selectedValue}
        placeholder={placeholder}
        aria-label={ariaLabel}
        isDisabled={isDisabled}
        maxMenuHeight={180}
        menuShouldScrollIntoView
      />
      {fieldState?.error && <IonNote className="nxu-select__error-note">{fieldState.error?.message}</IonNote>}
    </div>
  );
};

export default NxuSelect;
