import React from "react";

import "./document-viewer.scss";

export interface DocumentViewerProps {
  frameTitle: string;
  file: string;
}

/**
 * Component for rendering html or PDF docs within the app
 */
const DocumentViewer = ({ file, frameTitle }: DocumentViewerProps) => (
  <iframe className="document-viewer__html" title={frameTitle} style={{ border: "0" }} srcDoc={file} />
);

export default DocumentViewer;
