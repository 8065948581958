import React, { useEffect, useMemo, useState } from "react";
import { IonButton, IonIcon, IonInput, IonNote } from "@ionic/react";
import { calendarOutline, fileTray, walletOutline } from "ionicons/icons";
import { Controller, SubmitHandler, useController, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { generateProgramStartDateList } from "utils/checkout-utils";
import { SelectItem } from "types/forms";

import { NxuComponentLoading } from "@nexford/nexford-ui-component-library";

import CardPanel from "components/atom/card-panel";
import NxuSelect from "components/atom/nxu-select";

import { HttpError } from "utils/errors/HttpError";
import { CurrencyProvider, PaymentEstimate, PaymentsTypeOptions, PaymentTypes, TuitionFeeError } from "types/payments";
import { OfferCoupons } from "constants/offers";

import "./payment-details-form.scss";
import AlertCardPanel from "components/atom/alert-card-panel";
import { useRegistrationContext } from "utils/context/registration";

// Application Form validation schema
const checkoutFormSchema = yup.object().shape({
  StartDate: yup.string().required("Start Date is a required field"),
  CouponCode: yup.string().nullable().optional(),
});

const formatMoney = (amount?: number | null) =>
  amount ? `${parseFloat(amount.toFixed(2)).toLocaleString("en-US")}` : 0;

export interface PaymentDetailsFormProps {
  checkoutType: PaymentTypes;
  isDegreeTuitionFee?: boolean;
  checkoutLocked?: boolean;
  programStartDates?: string[];
  programTuitionFee: PaymentEstimate | null;
  programTuitionFeeError: Error | null;
  programTuitionFeeIsFetching: boolean;
  paymentProvidersFetching: boolean;
  paymentSubmitting: boolean;
  currencies?: Array<CurrencyProvider>;
  // Allow form to set detail values on the parent checkout
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  setSelectedMonth: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCouponCodeValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsCouponApplied: React.Dispatch<React.SetStateAction<boolean>>;
  // Return a validated coupon code from the parent checkout
  isCouponApplied: boolean;
  validCouponCode: string | null;
  isSkipTuition?: boolean;
  tuitionFeeWillBePaidByPartner?: boolean;
}

/**
 * ApplyNXU Application Checkout
 */
export const PaymentDetailsForm = ({
  checkoutType,
  isDegreeTuitionFee,
  programStartDates,
  programTuitionFee,
  programTuitionFeeError,
  programTuitionFeeIsFetching,
  paymentProvidersFetching,
  paymentSubmitting,
  currencies,
  setSelectedDate,
  setSelectedMonth,
  setCouponCodeValue,
  setIsCouponApplied,
  isCouponApplied,
  validCouponCode,
  isSkipTuition,
  tuitionFeeWillBePaidByPartner,
}: PaymentDetailsFormProps) => {
  const [isApplicationFee] = useState<boolean>(checkoutType === PaymentsTypeOptions.ApplicationFee);
  const [isCouponLoading, setIsCouponLoading] = useState<boolean>(false);
  const [isNxuNgnCoupon, setIsNxuNgnCoupon] = useState<boolean>(false);
  const [dateOptions, setDateOptions] = useState<Array<SelectItem>>();
  const { partnerDetails } = useRegistrationContext();

  const newCheckoutForm = useForm({
    defaultValues: {
      StartDate: "",
      CouponCode: "",
    },
    resolver: yupResolver(checkoutFormSchema),
  });

  const { control, handleSubmit, setValue } = newCheckoutForm;

  const handleCheckoutSubmit: SubmitHandler<any> = async () => {};

  const {
    field: { value: StartDateValue, onChange: StartDateOnChange },
  } = useController({ name: "StartDate", control });

  const {
    field: { value: CouponCodeValue, onChange: CouponCodeOnChange },
  } = useController({ name: "CouponCode", control });

  const isCouponCodeInvalid =
    (programTuitionFeeError as HttpError<TuitionFeeError>)?.data?.ErrorCode == "InvalidCouponCode";

  const canApplyCouponCode = CouponCodeValue && (!isCouponApplied || isCouponCodeInvalid);

  const formTitle = useMemo(() => {
    switch (true) {
      case isApplicationFee:
        return "Before we can review your details, you'll need to pay your application fee";
      case tuitionFeeWillBePaidByPartner && !isDegreeTuitionFee:
        return "Select your Start Date and enroll";
      case isDegreeTuitionFee:
        return "Select your Start Date to continue";
      default:
        return "Payment";
    }
  }, [isApplicationFee, isDegreeTuitionFee, tuitionFeeWillBePaidByPartner]);

  // Once start dates come through, populate the form drop down and set initial values
  useEffect(() => {
    if (isApplicationFee) {
      setSelectedDate("");
      setSelectedMonth(0);
    } else if (programStartDates?.length && !dateOptions?.length) {
      const dateList = generateProgramStartDateList(programStartDates);
      setDateOptions(dateList);
      const initialDate = dateList[0].value as string;
      setValue("StartDate", initialDate);
      setSelectedDate(initialDate);
      setSelectedMonth(Number(initialDate.slice(5, 7)));
    }
  }, [isApplicationFee, dateOptions?.length, programStartDates, setSelectedDate, setSelectedMonth, setValue]);

  useEffect(() => {
    if (isCouponApplied) {
      if (programTuitionFeeIsFetching || (paymentProvidersFetching && isNxuNgnCoupon)) {
        setIsCouponLoading(true);
      } else {
        setIsCouponLoading(false);
      }
    }
  }, [programTuitionFeeIsFetching, paymentProvidersFetching, isNxuNgnCoupon, isCouponApplied]);

  useEffect(() => {
    if (isCouponApplied) {
      setValue("CouponCode", validCouponCode);
    }
    if (CouponCodeValue && validCouponCode && OfferCoupons.NxuNgn.test(CouponCodeValue)) {
      setIsNxuNgnCoupon(true);
    }
  }, [CouponCodeValue, validCouponCode]);

  const NgnAmount = isNxuNgnCoupon && currencies ? currencies.find((c) => c.currencyCode == "NGN")?.amount : null;

  const showStartDate = !isApplicationFee;
  const showCouponCode = isApplicationFee || !isSkipTuition;
  const showFee = isApplicationFee || !isSkipTuition;
  const showCouponAppliedMessage = !!validCouponCode && !isCouponLoading;
  const showTuitionWillBePaidMessage = tuitionFeeWillBePaidByPartner;

  return (
    <CardPanel className="checkout-page__payment-details" testId="checkout-page__payment-details">
      <h2>{formTitle}</h2>

      <form className="checkout-form" onSubmit={handleSubmit(handleCheckoutSubmit)} data-testid="checkout-form">
        {showStartDate && (
          <div className="checkout-form__row" data-testid="checkout-form__start-date">
            <div className="checkout-form__row--left">
              <IonIcon aria-label="Select Start Date" icon={calendarOutline} />
              <span>Start Date</span>
            </div>

            <Controller
              control={control}
              name="StartDate"
              render={({ field, fieldState }) => (
                <>
                  <NxuSelect
                    selectedValue={StartDateValue}
                    onChange={(option) => {
                      setSelectedDate(option.value);
                      setSelectedMonth(Number(option.value.slice(5, 7)));
                      StartDateOnChange(option.value);
                    }}
                    options={dateOptions || []}
                    field={field}
                    fieldState={fieldState}
                    placeholder={"Select your start date"}
                    isDisabled={!dateOptions || paymentSubmitting || programTuitionFeeIsFetching}
                  />
                </>
              )}
            />
          </div>
        )}

        {showCouponCode && (
          <div className="checkout-form__row">
            <div className="checkout-form__row--left">
              <span aria-label="Enter coupon code" className="nxu-icon nxu-icon-Voucher_icon_payment" />
              <span>Coupon Code</span>
            </div>

            <Controller
              control={control}
              name="CouponCode"
              render={({ field }) => (
                <div className="checkout-form__coupon-code-container">
                  <IonInput
                    data-testid="coupon-code-input"
                    fill="outline"
                    disabled={paymentSubmitting || (isCouponApplied && programTuitionFeeIsFetching)}
                    onIonInput={(e) => {
                      setIsCouponApplied(false);
                      setCouponCodeValue(e.detail.value || "");
                      CouponCodeOnChange(e);
                    }}
                    onIonBlur={field.onBlur}
                    type="text"
                    placeholder="Enter code"
                    aria-label="Enter coupon code"
                    errorText="This coupon code is invalid"
                    className={isCouponCodeInvalid ? "coupon-code_input ion-touched ion-invalid" : "coupon-code_input"}
                    value={field.value}
                  />

                  <IonButton
                    disabled={!canApplyCouponCode || paymentSubmitting}
                    onClick={() => setIsCouponApplied(true)}
                    aria-label="Apply Coupon Code"
                    type="button"
                  >
                    Apply
                  </IonButton>
                </div>
              )}
            />
          </div>
        )}

        {showFee && (
          <div className="checkout-form__row">
            <div className="checkout-form__row--left">
              <IonIcon icon={walletOutline} />
              {isApplicationFee ? "Application Fee" : "Tuition Fee"}
            </div>
            <div data-testid="tuition-fee">
              {isCouponLoading ? (
                <NxuComponentLoading noMargin={true} />
              ) : (
                !!programTuitionFee && `$${formatMoney(programTuitionFee?.Total)}`
              )}
            </div>
          </div>
        )}

        {showCouponAppliedMessage && (
          <div className="checkout-form__row checkout-form__row__coupon-applied-message">
            <div className="checkout-form__row--left" />
            <IonNote data-testid="coupon-applied-message">
              {isNxuNgnCoupon ? (
                <>
                  Stable Tuition Scholarship applied. Monthly tuition cost of {formatMoney(NgnAmount)} NGN for duration
                  of enrollment. This scholarship is subject to revocation for non-payment of fees or in the case of
                  withdrawal or dismissal from the university.
                </>
              ) : (
                <>
                  Was ${formatMoney(programTuitionFee?.Subtotal)}, coupon code {validCouponCode} applied
                </>
              )}
            </IonNote>
          </div>
        )}

        {showTuitionWillBePaidMessage && (
          <AlertCardPanel
            title={`Congratulations! Your tuition will be paid by our partner, ${partnerDetails?.name}.`}
            message="Select the Enroll button below to finalize your enrollment."
            status="success"
            noBorder
          />
        )}
      </form>
    </CardPanel>
  );
};

export default PaymentDetailsForm;
