import { useMutation, useQuery } from "@tanstack/react-query";
import { IdentityInfoData, TempFileResponse, FetchIdentityProfileResponse } from "types/learner-profile";
import { apiGet, apiPost, apiPostFile } from "./api-client";

export const learnerErrMsg = {
  uploadDocument:
    "Uh-oh! There was an error on uploading your document. Please refresh and try again or contact support.",
};

const fetchIdentityProfile = async (): Promise<FetchIdentityProfileResponse> =>
  apiGet("/api/learner-identity-api/self/identity-info");

const useIdentityProfileInfo = (token: string) =>
  useQuery({
    queryKey: ["identityProfile", token],
    queryFn: () => fetchIdentityProfile(),
    enabled: !!token,
    refetchOnWindowFocus: false,
  });

// Post the document file to api store
const useFileUploadService = () =>
  useMutation({
    mutationFn: (prop: { payload: { file: File }; fileExtension: string }): Promise<TempFileResponse> =>
      apiPostFile(
        `/api/learner-files/learners/files/temp/${prop.fileExtension}`,
        prop.payload.file,
        learnerErrMsg.uploadDocument,
      ),
  });

const submitIdentityInfo = async (identityInfoData: IdentityInfoData): Promise<IdentityInfoData> =>
  apiPost("/api/learner-identity-api/submit", identityInfoData);

const useSubmitIdentifyInfo = () =>
  useMutation({
    mutationFn: (identityInfoData: IdentityInfoData): Promise<IdentityInfoData> => submitIdentityInfo(identityInfoData),
  });

export { useIdentityProfileInfo, useFileUploadService, useSubmitIdentifyInfo };
