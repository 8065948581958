import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IonAccordion, IonAccordionGroup, IonButton, IonIcon, IonItem, IonLabel, IonNote } from "@ionic/react";
import { keyOutline, layersOutline, schoolOutline } from "ionicons/icons";

import { useRegistrationContext } from "utils/context/registration";
import CardPanel from "components/atom/card-panel";
import { NxuComponentLoading, NxuPrimaryButton, NxuSecondaryButton } from "@nexford/nexford-ui-component-library";
import GetInTouch from "components/atom/get-in-touch";
import { isMobileOS } from "utils/detect-device";
import { LocalRoutes } from "constants/routes";
import { StorageKeys } from "constants/storage-keys";

import "./learning-path-page.scss";

interface PathWrapperProps {
  productCode: string;
  foundationCoursesCandidate: null | string;
  firstCourseCandidate: boolean;
  SwitchApplication: (newType: string, newCode: string) => void;
  loading: boolean;
}

// Component blocks for the inner content shared between Desktop & Mobile displays
const FoundationsContent = (props: {
  SwitchApplication: (newType: string, newCode: string) => void;
  loading: boolean;
  productCode: string;
}) => {
  const switchToFND = () => props.SwitchApplication("Certificate", "FND5000");
  const switchToFDT = () => props.SwitchApplication("Certificate", "FDT5000");
  const switchToFBA = () => props.SwitchApplication("Certificate", "FBA5000");

  if (props.productCode === "MSDT") {
    return (
      <>
        <p className="application-page__learning-path__chip">Recommended</p>
        <p>
          If your undergraduate degree is not in Information Technology or a similar field, you'll need to take a
          one-course Foundations of Digital Transformation certificate before getting starting your degree.
        </p>
        <p>You'll progress directly into the MS in Digital Transformation when you:</p>
        <ul>
          <li>Complete this certificate</li>
          <li>Provide a copy of your ID and proof of your degree</li>
        </ul>
        <NxuPrimaryButton onClick={switchToFDT} disabled={props.loading}>
          Enroll in FDT5000
        </NxuPrimaryButton>
      </>
    );
  }

  if (props.productCode === "MSBA") {
    return (
      <>
        <p className="application-page__learning-path__chip">Recommended</p>
        <p>
          If your undergraduate degree did not include Business Administration and Statistics, you will need to take two
          foundational courses - one for each topic.
        </p>
        <p>You'll progress directly into the MS in Data Analytics when you:</p>
        <ul>
          <li>Complete this certificate</li>
          <li>Provide a copy of your ID and proof of your degree</li>
        </ul>
        <NxuPrimaryButton onClick={switchToFBA} disabled={props.loading}>
          Enroll in FBA5000
        </NxuPrimaryButton>
      </>
    );
  }

  return (
    <>
      <p className="application-page__learning-path__chip">Recommended</p>
      <p>
        As your undergraduate degree is not in a business field, you'll need to take the one-course Business
        Fundamentals certificate before getting started in your MBA.
      </p>
      <p>You'll progress directly into the MBA when you:</p>
      <ul>
        <li>Complete this certificate</li>
        <li>Provide a copy of your ID and proof of your degree</li>
      </ul>
      <NxuPrimaryButton onClick={switchToFND} disabled={props.loading}>
        Enroll in FND5000
      </NxuPrimaryButton>
    </>
  );
};

const DegreeContent = (props: { loading: boolean; productCode: string }) => {
  const navigate = useNavigate();
  const navigateToApplication = (productCode: string) =>
    navigate({
      pathname: LocalRoutes.APPLICATION,
      search: `type=Degree&product=${productCode}`,
    });

  if (props.productCode === "MSDT") {
    return (
      <>
        <p>
          Complete an application to the <strong>MS in Digital Transformation</strong> with a $25 application fee. This
          option is best suited for people with an undergraduate degree in an IT-related field, or those unsure if their
          degree qualifies.
        </p>
        <p>
          Our admissions team will review your application, offering direct admission to the MS in Digital
          Transformation or recommending the Foundations Certificate based on your qualifications.
        </p>

        <IonButton
          className="secondary-button"
          size="small"
          fill="outline"
          shape="round"
          color="secondary"
          disabled={props.loading}
          onClick={() => navigateToApplication("MSDT")}
        >
          Continue Application
        </IonButton>
      </>
    );
  }

  if (props.productCode === "MSBA") {
    return (
      <>
        <p>
          Complete an application to the <strong>MS in Data Analytics</strong> with a $25 application fee. This option
          is best suited for people with an undergraduate degree that covered Business Administration and Statistics, or
          those unsure if their degree qualifies.
        </p>
        <p>
          Our admissions team will review your application, offering direct admission to the MS in Data Analytics or
          recommending the Foundations Certificate based on your qualifications.
        </p>

        <IonButton
          className="secondary-button"
          size="small"
          fill="outline"
          shape="round"
          color="secondary"
          disabled={props.loading}
          onClick={() => navigateToApplication("MSBA")}
        >
          Continue Application
        </IonButton>
      </>
    );
  }

  return (
    <>
      <p>
        Apply directly to the <strong>MBA</strong> program with a $25 application fee.
      </p>
      <p>
        Our admissions team will review your application, offering direct admission to the MBA or recommending the
        Foundations Certificate based on your qualifications.
      </p>

      <IonButton
        className="secondary-button"
        size="small"
        fill="outline"
        shape="round"
        color="secondary"
        disabled={props.loading}
        onClick={() => navigateToApplication("MBA")}
      >
        Continue Application
      </IonButton>
    </>
  );
};

const CourseContent = (props: { SwitchApplication: (newType: string, newCode: string) => void; loading: boolean }) => {
  const switchToSingleCourse = () => props.SwitchApplication("Course", "BUS6100");

  return (
    <>
      <p>Not sure about committing to the full MBA?</p>
      <p>
        Register for the <strong>Global Business Course</strong> and see what online learning and the Nexford University
        experience is like.
      </p>
      <p>When you're ready to commit to an MBA, you'll already have completed the first course!</p>
      <NxuSecondaryButton onClick={switchToSingleCourse} disabled={props.loading}>
        Start with a course
      </NxuSecondaryButton>
    </>
  );
};

// Accordion component wrapper for Mobile devices
const MobileAccordion = (props: PathWrapperProps) => (
  <IonAccordionGroup
    className="application-page__learning-path__accordion"
    expand="inset"
    value={!props.firstCourseCandidate && props.foundationCoursesCandidate !== "true" ? "mba" : ""}
  >
    <IonAccordion value="title" readonly={true}>
      <IonItem className="application-page__learning-path__accordion-title" slot="header">
        <IonLabel>Choose your learning path</IonLabel>
      </IonItem>
    </IonAccordion>
    {props.foundationCoursesCandidate === "true" && (
      <IonAccordion value="foundations">
        <IonItem slot="header">
          <IonIcon className="application-page__learning-path__accordion-icon" icon={keyOutline} slot="start" />
          <IonLabel>
            {props.productCode === "MSBA" ? "Get foundational skills" : "Start your foundation course"} <br />
            <IonNote>
              {props.productCode === "MBA" &&
                "Get started by taking our Advanced Business Foundation Certificate (FND5000)"}
              {props.productCode === "MSBA" &&
                "Get started by taking our Advanced Business Foundation Certificate (FBA5000)"}
              {props.productCode === "MSDT" && "Get started by taking our foundational courses (FDT5000)"}
            </IonNote>
          </IonLabel>
        </IonItem>
        <div slot="content">
          <CardPanel
            className="application-page__learning-path__card application-page__learning-path__card--rec"
            testId="fnd-path-card"
          >
            <FoundationsContent
              SwitchApplication={props.SwitchApplication}
              loading={props.loading}
              productCode={props.productCode}
            />
          </CardPanel>
        </div>
      </IonAccordion>
    )}
    <IonAccordion value="mba">
      <IonItem slot="header">
        <IonIcon className="application-page__learning-path__accordion-icon" icon={schoolOutline} slot="start" />
        <IonLabel>
          Submit a full application <br />
          <IonNote>Apply directly to the degree program...</IonNote>
        </IonLabel>
      </IonItem>
      <div slot="content">
        <CardPanel className="application-page__learning-path__card" testId="degree-path-card">
          <DegreeContent loading={props.loading} productCode={props.productCode} />
        </CardPanel>
      </div>
    </IonAccordion>
    {props.firstCourseCandidate && (
      <IonAccordion value="course">
        <IonItem slot="header">
          <IonIcon className="application-page__learning-path__accordion-icon" icon={layersOutline} slot="start" />
          <IonLabel>
            Try the first MBA course
            <br />
            <IonNote>Not sure about committing to the full MBA...</IonNote>
          </IonLabel>
        </IonItem>

        <div slot="content">
          <CardPanel className="application-page__learning-path__card">
            <CourseContent SwitchApplication={props.SwitchApplication} loading={props.loading} />
          </CardPanel>
        </div>
      </IonAccordion>
    )}
  </IonAccordionGroup>
);

// Flex row component wrapper for Desktop devices
const DesktopRow = (props: PathWrapperProps) => (
  <>
    <CardPanel>
      <h2 className="application-page__learning-path__title">Choose your learning path</h2>
    </CardPanel>
    <div className="application-page__learning-path__flow">
      {props.foundationCoursesCandidate === "true" && (
        <CardPanel
          className="application-page__learning-path__card application-page__learning-path__card--rec"
          testId="fnd-path-card"
        >
          <h3>{props.productCode === "MSBA" ? "Get foundational skills" : "Start your foundation course"}</h3>
          <FoundationsContent
            SwitchApplication={props.SwitchApplication}
            loading={props.loading}
            productCode={props.productCode}
          />
        </CardPanel>
      )}
      <CardPanel className="application-page__learning-path__card" testId="degree-path-card">
        <h3>Submit a full application</h3>
        <DegreeContent loading={props.loading} productCode={props.productCode} />
      </CardPanel>
      {props.firstCourseCandidate && (
        <CardPanel className="application-page__learning-path__card">
          <h3>Try the first MBA course</h3>
          <CourseContent SwitchApplication={props.SwitchApplication} loading={props.loading} />
        </CardPanel>
      )}
    </div>
  </>
);

/**
 * Learning Path page
 */
const LearningPathPage = () => {
  const { SwitchApplication, loading, registrationData } = useRegistrationContext();

  const [foundationCoursesCandidate] = useState(sessionStorage.getItem(StorageKeys.FOUNDATION_COURSES_CANDIDATE));
  const [firstCourseCandidate, setFirstCourseCandidate] = useState<boolean | undefined>();

  useEffect(() => {
    if (firstCourseCandidate === undefined) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setFirstCourseCandidate(!timezone.startsWith("Africa/") && registrationData?.productCode === "MBA");
    }
  }, [firstCourseCandidate, registrationData]);

  if (firstCourseCandidate === undefined) return <NxuComponentLoading />;

  return (
    <div className="application-page__screening-panel">
      {isMobileOS() ? (
        <MobileAccordion
          productCode={registrationData?.productCode || ""}
          foundationCoursesCandidate={foundationCoursesCandidate}
          firstCourseCandidate={firstCourseCandidate}
          SwitchApplication={SwitchApplication}
          loading={loading}
        />
      ) : (
        <DesktopRow
          productCode={registrationData?.productCode || ""}
          foundationCoursesCandidate={foundationCoursesCandidate}
          firstCourseCandidate={firstCourseCandidate}
          SwitchApplication={SwitchApplication}
          loading={loading}
        />
      )}
      <GetInTouch type="admission">
        <p>Not sure which to pick? Contact us</p>
      </GetInTouch>
    </div>
  );
};

export default LearningPathPage;
