import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IonIcon } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import cx from "classnames";

import { usePaymentProviders, usePaymentStatus, useProgramFee } from "utils/hooks/payments";
import { StripeProvider } from "utils/context/Stripe";

import { NxuAlert, NxuComponentLoading } from "@nexford/nexford-ui-component-library";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import CardPanel from "components/atom/card-panel";
import GetInTouch from "components/atom/get-in-touch";
import PaymentProviderOptions from "components/molecule/payment-provider-options";
import PaymentDetailsForm from "components/molecule/payment-details-form";
import EnrollmentAgreement from "components/molecule/enrollment-agreement";
import { useIdentityProfileInfo } from "utils/hooks/learner-identity";

import { useRegistrationContext } from "utils/context/registration";
import { RegistrationStatus, RequirementStatus, RequirementType } from "types/registrations";
import { useStartDateOptions } from "utils/hooks/product-availability";
import { HttpError } from "utils/errors/HttpError";
import {
  PaymentEstimate,
  PaymentPollingStatus,
  PaymentsTypeOptions,
  PaymentTypes,
  TuitionFeeError,
} from "types/payments";

import "./checkout.scss";
import PartnerPaymentForm from "components/molecule/partner-payment-form";
import PartnerSponsoredCheckout from "components/molecule/partner-sponsored-checkout";

export interface CheckoutPageProps {
  checkoutType: PaymentTypes;
}

/**
 * ApplyNXU Application Checkout
 */
export const CheckoutPage = (props: CheckoutPageProps) => {
  const {
    registrationData,
    programDetails,
    email,
    learnerId,
    learnerProfileData,
    partnerDetails,
    token,
    CompleteRequirements,
  } = useRegistrationContext();

  const [isEnrollmentAgreementLoaded, setIsEnrollmentAgreementLoaded] = useState<boolean>(false);
  const [isEnrollmentAgreementSigned, setIsEnrollmentAgreementSigned] = useState<boolean>(false);

  const [isCouponApplied, setIsCouponApplied] = useState<boolean>(false);
  const [validCoupon, setValidCoupon] = useState<string | null>(null);
  const [isSkipCoupon, setIsSkipCoupon] = useState<boolean>(false);

  const [couponCodeValue, setCouponCodeValue] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState<number>();

  const [paymentSubmitting, setPaymentSubmitting] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<
    PaymentPollingStatus.SUBMITTED | PaymentPollingStatus.PAID | undefined
  >();
  const [programFee, setProgramFee] = useState<PaymentEstimate | null>(null);

  const isApplicationFee = props.checkoutType === PaymentsTypeOptions.ApplicationFee;

  const PROGRAM_FEE_SUBMISSION_ERROR: { [key: string]: any } = {
    InvalidTierForPartner: `The ${programDetails?.FriendlyName} program is currently unavailable in the selected country of residence under the partnership with ${partnerDetails?.name}. For further assistance or alternative options, please contact support.`,
  };

  const isDegreeTuitionFee = useMemo(() => {
    const admissionDecisionReq = registrationData?.requirements.find(
      (item) => item.requirement === RequirementType.AdmissionDecision,
    );

    return (
      props.checkoutType === PaymentsTypeOptions.TuitionFee &&
      admissionDecisionReq &&
      admissionDecisionReq.status === RequirementStatus.Fulfilled
    );
  }, [props.checkoutType, registrationData?.requirements]);

  const isLearnerPaysPartner = useMemo(() => {
    const confirmPartnerTuitionRequirement = registrationData?.requirements.find(
      (item) => item.requirement === RequirementType.ConfirmPartnerTuitionPayment,
    );

    return props.checkoutType === PaymentsTypeOptions.TuitionFee && !!confirmPartnerTuitionRequirement;
  }, [props.checkoutType, registrationData?.requirements]);

  //
  const { data: identityProfileApiData, isFetching: identityProfileDataFetching } = useIdentityProfileInfo(token || "");

  // Get program start date options, will fetch when token is found in session store
  const {
    data: programStartDates,
    isLoading: programStartDatesIsLoading,
    error: programStartDatesError,
  } = useStartDateOptions(programDetails?.ProductType, programDetails?.ProductCode);

  // Get tuition fee, will fetch when start date is set
  const {
    data: programFeeData,
    isFetching: programFeeIsFetching,
    error: programFeeError,
    refetch: fetchProgramFee,
  } = useProgramFee(
    props.checkoutType,
    programDetails?.ProductType,
    programDetails?.ProductCode,
    selectedDate,
    learnerProfileData?.Country,
    isCouponApplied ? couponCodeValue : null,
    registrationData?.partnerId,
  );

  // Get payment providers, will only fetch when manually called
  const {
    data: paymentProviders,
    isFetching: paymentProvidersFetching,
    error: paymentProvidersError,
    refetch: paymentProvidersRequestFetch,
  } = usePaymentProviders(
    props.checkoutType,
    registrationData?.productType || "",
    registrationData?.productCode || "",
    programFee?.Total || 0,
    learnerProfileData?.Country || "",
    isApplicationFee ? 0 : selectedMonth || 1,
    isApplicationFee ? 0 : 1,
    isCouponApplied ? couponCodeValue || "" : "",
    registrationData?.partnerId || "",
  );

  const requirementsToComplete = useMemo(() => {
    if (isApplicationFee) {
      return [RequirementType.ApplicationFee];
    } else {
      const possibleRequirements = new Set([
        RequirementType.StartDate,
        RequirementType.Agreement,
        RequirementType.TuitionFee,
        RequirementType.ConfirmPartnerTuitionPayment,
      ]);

      if (!registrationData) {
        return [];
      }

      return registrationData?.requirements
        .map(({ requirement }) => requirement)
        .filter((req) => possibleRequirements.has(req));
    }
  }, [registrationData, isApplicationFee]);

  const hasTuitionFeeRequirement = useMemo(
    () => registrationData?.requirements.some(({ requirement }) => requirement === RequirementType.TuitionFee),
    [registrationData?.requirements],
  );

  const hasPartner = Boolean(registrationData?.partnerId);

  const tuitionFeeWillBePaidByPartner =
    hasPartner &&
    !isLearnerPaysPartner &&
    !hasTuitionFeeRequirement &&
    props.checkoutType === PaymentsTypeOptions.TuitionFee;

  // Get the applicant's current payment status on page load
  const {
    data: paymentStatusData,
    isLoading: paymentStatusIsLoading,
    error: paymentStatusError,
  } = usePaymentStatus(
    !isLearnerPaysPartner && !tuitionFeeWillBePaidByPartner,
    props.checkoutType,
    programDetails?.ProductType,
    programDetails?.ProductCode,
  );

  const isCouponCodeInvalid = (programFeeError as HttpError<TuitionFeeError>)?.data?.ErrorCode == "InvalidCouponCode";

  const hasPageError = programStartDatesError || paymentStatusError || (!isCouponCodeInvalid && programFeeError);

  const handleCompleteRequirements = useCallback(() => {
    CompleteRequirements(() => Promise.resolve(), requirementsToComplete);
  }, [CompleteRequirements, requirementsToComplete]);

  const handleSignAgreement = (signed: boolean) => {
    setIsEnrollmentAgreementSigned(signed);
  };

  // Trigger the payment providers request with a manual fetch call
  // Response includes a unique callback ID that is refreshed with each request
  useEffect(() => {
    if (
      (isApplicationFee || selectedMonth) &&
      programFee &&
      !isCouponCodeInvalid &&
      !isLearnerPaysPartner &&
      !tuitionFeeWillBePaidByPartner
    )
      paymentProvidersRequestFetch();
  }, [isApplicationFee, paymentProvidersRequestFetch, selectedMonth, programFee]);

  useEffect(() => {
    const shouldFetch =
      (isApplicationFee || selectedMonth) && (!couponCodeValue || (isCouponApplied && !isCouponCodeInvalid));
    if (shouldFetch) fetchProgramFee();
  }, [
    couponCodeValue,
    fetchProgramFee,
    isCouponApplied,
    isCouponCodeInvalid,
    selectedMonth,
    isApplicationFee,
    isLearnerPaysPartner,
    tuitionFeeWillBePaidByPartner,
  ]);

  useEffect(() => {
    if (!programFeeIsFetching && programFeeData) {
      setProgramFee(programFeeData);
      if (isCouponApplied && couponCodeValue) {
        setValidCoupon(couponCodeValue?.trim());
        if (programFeeData.Total === 0) setIsSkipCoupon(true);
      }
    }
  }, [programFeeIsFetching, isCouponApplied]);

  useEffect(() => {
    if (!isCouponApplied) {
      setValidCoupon(null);
      setIsSkipCoupon(false);
    }
  }, [isCouponApplied]);

  useEffect(() => {
    if (!paymentStatusIsLoading && paymentStatusData) {
      if (
        paymentStatusData.status === PaymentPollingStatus.SUBMITTED ||
        paymentStatusData.status === PaymentPollingStatus.PAID
      ) {
        setPaymentStatus(paymentStatusData.status);

        if (
          paymentStatusData.status === PaymentPollingStatus.PAID &&
          registrationData?.status !== RegistrationStatus.Completed
        ) {
          if (isApplicationFee) {
            CompleteRequirements(() => Promise.resolve(), [RequirementType.ApplicationFee]);
          } else {
            CompleteRequirements(() => Promise.resolve(), [RequirementType.TuitionFee]);
          }
        }
      } else setPaymentStatus(undefined);
    }
  }, [paymentStatusIsLoading, paymentStatusData]);

  useEffect(() => {
    const shouldRedirectToSuccessPage = registrationData?.requirements.every(
      ({ status }) => status === RequirementStatus.Fulfilled,
    );

    if (shouldRedirectToSuccessPage) {
      handleCompleteRequirements();
    }
  }, [
    handleCompleteRequirements,
    isEnrollmentAgreementSigned,
    registrationData?.requirements,
    requirementsToComplete,
    tuitionFeeWillBePaidByPartner,
  ]);

  if (
    paymentStatusIsLoading ||
    programStartDatesIsLoading ||
    programFeeIsFetching ||
    !learnerProfileData ||
    identityProfileDataFetching
  ) {
    return (
      <PageContent className="checkout-page">
        <NxuComponentLoading />
      </PageContent>
    );
  }

  if (hasPageError) {
    return (
      <PageContent className="checkout-page" fullWidth>
        <NxuAlert
          type={PROGRAM_FEE_SUBMISSION_ERROR[programFeeError?.data.ErrorCode] ? "warning" : "error"}
          message={
            paymentStatusError?.message ||
            programStartDatesError?.message ||
            PROGRAM_FEE_SUBMISSION_ERROR[programFeeError?.data.ErrorCode] ||
            programFeeError?.message
          }
        />
      </PageContent>
    );
  }

  if (paymentStatus) {
    return (
      <PageContent className="checkout-page" fullWidth>
        <CardPanel testId="checkout-page__pending-msg" centre className="applicant-status__info-panel">
          <IonIcon icon={alertCircleOutline} />
          {paymentStatus === PaymentPollingStatus.PAID && (
            <p>We have received your payment and are in the process of finalising your application.</p>
          )}
          {paymentStatus === PaymentPollingStatus.SUBMITTED && (
            <p>
              Your payment is in progress. When it is confirmed, we'll email you with everything you need to complete
              your application. This can take up to 3 days so please keep an eye on your inbox!
            </p>
          )}
        </CardPanel>
      </PageContent>
    );
  }

  if (isDegreeTuitionFee) {
    return (
      <PageContent className="checkout-page" fullWidth>
        <section data-testid="application-nav-stepper" className="application-nav-stepper">
          <div>
            <span
              aria-label={`Step 1 - start date`}
              className={cx(
                "application-nav-stepper__step application-nav-stepper__step--disabled",
                isEnrollmentAgreementLoaded && "application-nav-stepper__step--complete",
                !isEnrollmentAgreementLoaded && "application-nav-stepper__step--current",
              )}
            >
              1
            </span>
          </div>
          <div>
            <span
              aria-label={`Step 2 - enrollment agreement`}
              className={cx(
                "application-nav-stepper__step application-nav-stepper__step--disabled",
                isEnrollmentAgreementSigned && "application-nav-stepper__step--complete",
                isEnrollmentAgreementLoaded && !isEnrollmentAgreementSigned && "application-nav-stepper__step--current",
              )}
            >
              2
            </span>
          </div>
          {!tuitionFeeWillBePaidByPartner && (
            <div>
              <span
                aria-label={`Step 3 - tuition fee`}
                className={cx(
                  "application-nav-stepper__step application-nav-stepper__step--disabled",
                  isEnrollmentAgreementLoaded &&
                    isEnrollmentAgreementSigned &&
                    "application-nav-stepper__step--current",
                )}
              >
                3
              </span>
            </div>
          )}
        </section>
        <PaymentDetailsForm
          checkoutType={props.checkoutType}
          isDegreeTuitionFee={isDegreeTuitionFee}
          programStartDates={programStartDates}
          programTuitionFee={programFee}
          programTuitionFeeError={programFeeError}
          programTuitionFeeIsFetching={programFeeIsFetching}
          paymentProvidersFetching={paymentProvidersFetching}
          paymentSubmitting={paymentSubmitting}
          currencies={paymentProviders?.currencies}
          setSelectedDate={setSelectedDate}
          setSelectedMonth={setSelectedMonth}
          setIsCouponApplied={setIsCouponApplied}
          setCouponCodeValue={setCouponCodeValue}
          isCouponApplied={isCouponApplied}
          validCouponCode={validCoupon}
          isSkipTuition={!hasTuitionFeeRequirement}
          tuitionFeeWillBePaidByPartner={false}
        />
        <EnrollmentAgreement
          checkoutType={props.checkoutType}
          identity={identityProfileApiData}
          estimates={programFee}
          couponCode={validCoupon}
          startDate={selectedDate}
          enableNext={!hasPageError && !!selectedDate && !!programFee}
          setIsAgreementLoaded={setIsEnrollmentAgreementLoaded}
          isAgreementSigned={isEnrollmentAgreementSigned}
          setIsAgreementSigned={handleSignAgreement}
        />
        {isEnrollmentAgreementSigned && isLearnerPaysPartner && (
          <PartnerPaymentForm
            partnerId={registrationData!.partnerId}
            startMonth={selectedMonth || 0}
            amount={programFee?.Total || 0}
            learnerCountry={learnerProfileData.Country}
            productType={registrationData!.productType}
            productCode={registrationData!.productCode}
            onSuccess={handleCompleteRequirements}
            isDegreeTuitionFee
          />
        )}

        {isEnrollmentAgreementSigned && tuitionFeeWillBePaidByPartner && (
          <PartnerSponsoredCheckout
            startMonth={selectedMonth || 0}
            productType={registrationData!.productType}
            productCode={registrationData!.productCode}
            onSuccess={handleCompleteRequirements}
          />
        )}

        {isEnrollmentAgreementSigned && !tuitionFeeWillBePaidByPartner && !isLearnerPaysPartner && (
          <PaymentProviderOptions
            checkoutType={props.checkoutType}
            learnerId={learnerId!}
            partnerId={registrationData!.partnerId}
            email={email!}
            country={learnerProfileData.Country}
            programDetails={programDetails}
            startMonth={selectedMonth}
            couponCode={validCoupon && couponCodeValue ? couponCodeValue : null}
            allowSkipPayment={isSkipCoupon}
            paymentProviders={paymentProviders}
            paymentProvidersError={paymentProvidersError}
            loadingProviders={programFeeIsFetching || paymentProvidersFetching}
            paymentSubmitting={paymentSubmitting}
            setPaymentSubmitting={setPaymentSubmitting}
            onSuccess={handleCompleteRequirements}
          />
        )}
        <GetInTouch type="billings" />
      </PageContent>
    );
  }

  return (
    <PageContent className="checkout-page" fullWidth>
      <PaymentDetailsForm
        checkoutType={props.checkoutType}
        programStartDates={programStartDates}
        programTuitionFee={programFee}
        programTuitionFeeError={programFeeError}
        programTuitionFeeIsFetching={programFeeIsFetching}
        paymentProvidersFetching={paymentProvidersFetching}
        paymentSubmitting={paymentSubmitting}
        currencies={paymentProviders?.currencies}
        setSelectedDate={setSelectedDate}
        setSelectedMonth={setSelectedMonth}
        setIsCouponApplied={setIsCouponApplied}
        setCouponCodeValue={setCouponCodeValue}
        isCouponApplied={isCouponApplied}
        validCouponCode={validCoupon}
        isSkipTuition={!hasTuitionFeeRequirement}
        tuitionFeeWillBePaidByPartner={tuitionFeeWillBePaidByPartner}
      />

      {tuitionFeeWillBePaidByPartner && (
        <PartnerSponsoredCheckout
          startMonth={selectedMonth || 0}
          productType={registrationData!.productType}
          productCode={registrationData!.productCode}
          onSuccess={handleCompleteRequirements}
        />
      )}

      {isLearnerPaysPartner && (
        <PartnerPaymentForm
          partnerId={registrationData!.partnerId}
          startMonth={selectedMonth || 0}
          amount={programFee?.Total || 0}
          learnerCountry={learnerProfileData.Country}
          productType={registrationData!.productType}
          productCode={registrationData!.productCode}
          onSuccess={handleCompleteRequirements}
        />
      )}
      {!tuitionFeeWillBePaidByPartner && !isLearnerPaysPartner && (
        <PaymentProviderOptions
          checkoutType={props.checkoutType}
          learnerId={learnerId!}
          partnerId={registrationData!.partnerId}
          email={email!}
          country={learnerProfileData.Country}
          programDetails={programDetails}
          startMonth={selectedMonth}
          couponCode={validCoupon && couponCodeValue ? couponCodeValue : null}
          allowSkipPayment={isSkipCoupon}
          paymentProviders={paymentProviders}
          paymentProvidersError={paymentProvidersError}
          loadingProviders={programFeeIsFetching || paymentProvidersFetching}
          paymentSubmitting={paymentSubmitting}
          setPaymentSubmitting={setPaymentSubmitting}
          onSuccess={handleCompleteRequirements}
        />
      )}

      <GetInTouch type="billings" />
    </PageContent>
  );
};

const CheckoutWithStripe = ({ checkoutType }: CheckoutPageProps) => (
  <StripeProvider>
    <CheckoutPage checkoutType={checkoutType} />
  </StripeProvider>
);

export default CheckoutWithStripe;
