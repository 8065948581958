import React from "react";

import {
  IonButton,
  IonButtons,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonIcon,
  IonPopover,
} from "@ionic/react";
import { informationCircleOutline, layersOutline, timeOutline } from "ionicons/icons";
import { ProductDetails } from "types/admissions";

import "./product-details-modal.scss";

export interface ProductDetailsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  productName: string;
  productDetails?: ProductDetails;
}

/**
 * Display an info modal for payment methods that require leaving the app
 */
const ProductDetailsModal = (props: ProductDetailsModalProps) => {
  const { isOpen, closeModal, productDetails, productName } = props;

  const handleClose = () => {
    closeModal();
  };

  if (!productDetails) return null;

  return (
    <IonModal
      data-testid="product-details-modal"
      isOpen={isOpen}
      onDidDismiss={handleClose}
      className="nxu-modal product-details-modal"
    >
      <IonHeader className="product-details-modal__header">
        <IonToolbar>
          <IonTitle data-testid="product-details-modal-title">{productDetails.productCode}</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="outline" onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="product-details-modal__content">
        <div className="product-details-modal__content-inner">
          <h2>{productName}</h2>
          <div className="application-title-panel__row application-title-panel__stats-row">
            <div className="application-title-panel__row__content">
              <div>
                <IonIcon icon={timeOutline}></IonIcon>
                <span>1-2 Months</span>
              </div>
              {productDetails.degrees?.length && (
                <div>
                  <IonIcon icon={layersOutline}></IonIcon>
                  <span>Stacks into degree</span>
                </div>
              )}
            </div>
          </div>
          <div className="application-title-panel__row application-title-panel__desc-row">
            <h3>Course Description</h3>
            <p>{productDetails.description}</p>
          </div>
          {productDetails.skills?.length && (
            <div className="application-title-panel__row application-title-panel__row--tag-cloud">
              <h3>Skills you'll gain</h3>
              <div className="application-title-panel__row__content">
                {productDetails.skills?.map((e) => <span key={e}>{e}</span>)}
              </div>
            </div>
          )}
          {productDetails.degrees?.length && (
            <div className="application-title-panel__row application-title-panel__row--tag-cloud">
              <div className="application-title-panel__row__title">
                <h3>Stacks into</h3>
                <IonButton
                  id="stack-tooltip-trigger"
                  aria-label="Open the Stacked Credentials tooltip"
                  shape="round"
                  size="small"
                >
                  <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                </IonButton>
                <IonPopover
                  className="stack-tooltip"
                  trigger="stack-tooltip-trigger"
                  triggerAction="click"
                  side="bottom"
                  alignment="start"
                >
                  <p>
                    Our stacked credential model means that you can start with a course or certificate and fold those
                    into a larger degree.
                  </p>
                </IonPopover>
              </div>
              <div className="application-title-panel__row__content">
                {productDetails.degrees?.map((e) => <span key={e}>{e}</span>)}
              </div>
            </div>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ProductDetailsModal;
