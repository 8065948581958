import React from "react";
import { IonButton, IonSpinner } from "@ionic/react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import CardPanel from "components/atom/card-panel";

import "./partner-sponsored-checkout.scss";
import { useCreateSubscriptionRequest } from "utils/hooks/payments";
import ApplicationErrorBlock from "../application-error-block";

const FormSchema = yup.object().shape({});

export interface PartnerPaymentFormProps {
  productType: string;
  productCode: string;
  startMonth: number;
  onSuccess: () => void;
}

/**
 * ApplyNXU Application Checkout
 */
export const PartnerSponsoredCheckout = ({
  productType,
  productCode,
  startMonth,
  onSuccess,
}: PartnerPaymentFormProps) => {
  const confirmCodeForm = useForm({
    defaultValues: {},
    resolver: yupResolver(FormSchema),
  });

  const { handleSubmit } = confirmCodeForm;

  const { mutate: enroll, isError, isPending } = useCreateSubscriptionRequest({ onSuccess });

  const handleConfirmSubmit: SubmitHandler<any> = async () => {
    enroll({ productType, productCode, startMonth });
  };

  return (
    <div className="checkout-page__partner-checkout">
      <form onSubmit={handleSubmit(handleConfirmSubmit)} data-testid="enroll-form">
        <CardPanel testId="checkout-page__partner-checkout">
          {isError && <ApplicationErrorBlock />}

          <div className="enroll-form">
            <div className="save-button">
              <IonButton
                size="large"
                disabled={isPending}
                type="submit"
                data-testid="checkout-page__partner-enroll-form__save-button"
              >
                {isPending ? <IonSpinner /> : <>Enroll</>}
              </IonButton>
            </div>
          </div>
        </CardPanel>
      </form>
    </div>
  );
};

export default PartnerSponsoredCheckout;
