import React from "react";
import { IonIcon } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { alertCircleOutline } from "ionicons/icons";
import CardPanel from "components/atom/card-panel";
import { LocalRoutes } from "constants/routes";

import "./price-notification-block.scss";

// Timed block for providing notifications on price changes
const PriceNotificationBlock = () => {
  const location = useLocation();
  const hideOnRoutes: string[] = [
    LocalRoutes.APPLICATION_SUCCESS,
    LocalRoutes.APPLICATION_COMPLETE,
    LocalRoutes.APPLICATION_PENDING,
  ];

  const endDate = new Date("2024-06-02");
  const currentDate = new Date();

  const priceMsg = "Our tuition prices are increasing by at least 20% on June 1 — enroll now before you pay more!";

  if (currentDate > endDate || hideOnRoutes.includes(location.pathname)) return null;

  return (
    <CardPanel className="price-notification-block">
      <div className="price-notification-block--content">
        <IonIcon icon={alertCircleOutline} />
        <p>{priceMsg}</p>
      </div>
    </CardPanel>
  );
};

export default PriceNotificationBlock;
