import React from "react";
import cx from "classnames";
import "./card-panel.scss";

export interface CardPanelProps {
  children: React.ReactNode;
  className?: string;
  testId?: string;
  centre?: boolean;
}

/**
 * Standardised layout panel
 */
const CardPanel = ({ children, className, testId, centre }: CardPanelProps) => (
  <div className={cx("card-panel", centre && "card-panel--centre", className)} data-testid={testId || "card-panel"}>
    {children}
  </div>
);

export default CardPanel;
