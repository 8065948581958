import React, { useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";

import { LocalRoutes } from "constants/routes";
import { StorageKeys } from "constants/storage-keys";
import { Link, useLocation } from "react-router-dom";
import { RegistrationData, RegistrationRequirement, RequirementStatus, RequirementType } from "types/registrations";

import "./application-navigation.scss";
import { urlify } from "utils/urlify";
import { NavSteps } from "./types";
import { getNavigationSteps } from "./getNavigationSteps";

export interface ApplicationNavigationProps {
  productType: string;
  productCode: string;
  partnerId?: string;
  registrationData: RegistrationData | null;
}

const getDisplayableSteps = (
  steps: Array<NavSteps>,
  requirements: RegistrationRequirement[] | undefined,
): Array<NavSteps> => {
  const displayableNavStepsArray: Array<NavSteps> = [];
  steps.forEach((x) => {
    if (!requirements || x.requirements.some((r) => requirements.map((_) => _.requirement).includes(r))) {
      displayableNavStepsArray.push(x);
    }
  });
  return displayableNavStepsArray;
};

const ApplicationNavigation = (props: ApplicationNavigationProps) => {
  const { productType, productCode, registrationData, partnerId } = props;
  const location = useLocation();

  const [navSteps, setNavSteps] = useState<Array<NavSteps>>([]);

  const mapNavSteps = useCallback(() => {
    if (
      location.pathname === LocalRoutes.APPLICATION_SUCCESS ||
      location.pathname === LocalRoutes.APPLICATION_PENDING
    ) {
      setNavSteps([]);
      return;
    }

    const admissionDecisionReq = registrationData?.requirements.find(
      (item) => item.requirement === RequirementType.AdmissionDecision,
    );
    if (admissionDecisionReq && admissionDecisionReq.status === RequirementStatus.Fulfilled) {
      setNavSteps([]);
      return;
    }

    let navStepsArray: Array<NavSteps> = getNavigationSteps(productType, productCode);

    navStepsArray = getDisplayableSteps(navStepsArray, registrationData?.requirements);
    if (navStepsArray?.length) {
      navStepsArray.forEach((step, n) => {
        navStepsArray[n].isCurrent = step.route === location.pathname;
        let isComplete = true;
        step.requirements.forEach((stepRequirement) => {
          const matchedRequirement = registrationData?.requirements.find((r) => r.requirement === stepRequirement);
          if (isComplete && matchedRequirement?.status === RequirementStatus.Pending) isComplete = false;
        });
        navStepsArray[n].isComplete = isComplete;
        navStepsArray[n].isLink = n === 0 || isComplete || navStepsArray[n - 1].isComplete;
      });
    }

    setNavSteps([...navStepsArray]);
  }, [location.pathname, productType, productCode, registrationData?.requirements]);

  useEffect(() => {
    mapNavSteps();
  }, [mapNavSteps]);

  // Memoise the list of navigation steps
  const generateStepButton = useMemo(() => {
    // No steps to display
    if (!navSteps?.length) return null;

    return (
      <section data-testid="application-nav-stepper" className="application-nav-stepper">
        {navSteps.map((step, n) => (
          <div key={step.id}>
            {step.isLink && (
              <Link
                onClick={() =>
                  sessionStorage.setItem(
                    StorageKeys.CURRENT_ROUTE,
                    urlify(step.route, {
                      type: productType,
                      product: productCode,
                      partner: partnerId,
                    }),
                  )
                }
                to={urlify(step.route, {
                  type: productType,
                  product: productCode,
                  partner: partnerId,
                })}
                aria-label={`Go to ${step.friendlyName} page`}
                className={cx(
                  "application-nav-stepper__step",
                  step.isComplete && "application-nav-stepper__step--complete",
                  step.isCurrent && "application-nav-stepper__step--current",
                )}
              >
                {step.isComplete && !step.isCurrent ? <>&#10004;</> : <>{n + 1}</>}
              </Link>
            )}
            {!step.isLink && (
              <span
                aria-label={`Previous steps must be completed before ${step.friendlyName} can be reached`}
                className="application-nav-stepper__step application-nav-stepper__step--disabled"
              >
                {n + 1}
              </span>
            )}
          </div>
        ))}
      </section>
    );
  }, [navSteps, productCode, productType, partnerId]);

  return <>{generateStepButton}</>;
};

export default ApplicationNavigation;
