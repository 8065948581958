import React from "react";

import { IonPage, IonContent, IonButtons } from "@ionic/react";
import cx from "classnames";

import Header from "components/atom/header";
import { ReactComponent as LogoImg } from "assets/img/logos/logo-titlebar.svg";

import "./page-wrap.scss";

/**
 * Inner page component to be added to top level page components,
 * setting a standardised page width and padding
 */
export const PageContent = (props: { fullWidth?: boolean; className?: string; children: React.ReactNode }) => {
  const { children, className, fullWidth } = props;

  return (
    <div data-testid={"nxu-page-wrap"} className={cx("page-content", className, fullWidth ? "page-content__full" : "")}>
      {children}
    </div>
  );
};

/**
 * Page layout component inserting head/nav/layout to all standard page
 */
export const PageWrap = (props: { children: React.ReactNode }) => {
  const year = new Date().getFullYear();
  return (
    <IonPage data-testid="nxu-page-wrap" id="nxu-main-content">
      <Header />
      <IonContent>
        {props.children}
        <div className="nxu-footer">
          <div className="nxu-footer__toolbar">
            <div slot="start">
              <LogoImg aria-hidden="true" />
            </div>
            <div className="nxu-footer__title">&copy; Nexford University {year}. All rights reserved.</div>
            <IonButtons slot="end">
              <a href="mailto:success@nexford.org">Contact Us</a>
            </IonButtons>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PageWrap;
