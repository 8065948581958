import React from "react";
import { IonInput } from "@ionic/react";
import { Controller } from "react-hook-form";
import { UseFormReturn } from "react-hook-form/dist/types";

import { PaymentFormInputs } from "types/payments";

import "./cardholder-details.scss";

export interface CardholderDetailsProps {
  paymentForm: UseFormReturn<PaymentFormInputs>;
  isSubmitting: boolean;
}

/**
 * Partial form component returning the card holder details for a stripe payment
 */
const CardholderDetails = ({ paymentForm, isSubmitting }: CardholderDetailsProps) => {
  const {
    control,
    formState: { errors },
  } = paymentForm;

  return (
    <div className="cardholder-form">
      <h3>Payment Details</h3>

      <Controller
        control={control}
        name="FirstName"
        render={({ field }) => (
          <IonInput
            disabled={isSubmitting}
            onIonInput={field.onChange}
            onIonBlur={field.onBlur}
            fill="outline"
            type="text"
            placeholder="First Name"
            aria-label="First Name"
            errorText={errors.FirstName?.message}
            className={errors.FirstName ? "ion-touched ion-invalid" : ""}
          />
        )}
      />
      <Controller
        control={control}
        name="LastName"
        render={({ field }) => (
          <IonInput
            disabled={isSubmitting}
            onIonInput={field.onChange}
            onIonBlur={field.onBlur}
            fill="outline"
            type="text"
            placeholder="Last Name"
            aria-label="Last Name"
            errorText={errors.LastName?.message}
            className={errors.LastName ? "ion-touched ion-invalid" : ""}
          />
        )}
      />
      <Controller
        control={control}
        name="Email"
        render={({ field }) => (
          <IonInput
            disabled={isSubmitting}
            onIonInput={field.onChange}
            onIonBlur={field.onBlur}
            fill="outline"
            type="text"
            placeholder="Email"
            aria-label="Email"
            errorText={errors.Email?.message}
            className={errors.Email ? "ion-touched ion-invalid" : ""}
          />
        )}
      />
      <Controller
        control={control}
        name="Street"
        render={({ field }) => (
          <IonInput
            disabled={isSubmitting}
            onIonInput={field.onChange}
            onIonBlur={field.onBlur}
            fill="outline"
            type="text"
            placeholder="Street Address"
            aria-label="Street Address"
            errorText={errors.Street?.message}
            className={errors.Street ? "ion-touched ion-invalid" : ""}
          />
        )}
      />
      <Controller
        control={control}
        name="City"
        render={({ field }) => (
          <IonInput
            disabled={isSubmitting}
            onIonInput={field.onChange}
            onIonBlur={field.onBlur}
            fill="outline"
            type="text"
            placeholder="City"
            aria-label="City"
            errorText={errors.City?.message}
            className={errors.City ? "ion-touched ion-invalid" : ""}
          />
        )}
      />
      <Controller
        control={control}
        name="PostalCode"
        render={({ field }) => (
          <IonInput
            disabled={isSubmitting}
            onIonInput={field.onChange}
            onIonBlur={field.onBlur}
            fill="outline"
            type="text"
            placeholder="Post / Zip Code"
            aria-label="Post / Zip Code"
            errorText={errors.PostalCode?.message}
            className={errors.PostalCode ? "ion-touched ion-invalid" : ""}
          />
        )}
      />
    </div>
  );
};

export default CardholderDetails;
