import React from "react";
import { IonIcon } from "@ionic/react";
import CardPanel from "components/atom/card-panel";
import { alertCircleOutline } from "ionicons/icons";
import "./applicant-status-block.scss";
import { enrolledMsg, rejectedMsg } from "./applicant-status-messages";
import { ApplicationStatusOptions } from "../../../types/admissions";
import { RegistrationStatus } from "../../../types/registrations";

export interface ApplicantStatusBlockProps {
  admissionStatus: string;
}

// Content block for applicants unable to proceed
const ApplicantStatusBlock = ({ admissionStatus }: ApplicantStatusBlockProps) => {
  const infoMessage = () => {
    switch (admissionStatus) {
      case RegistrationStatus.Rejected:
      case ApplicationStatusOptions.REJECTED:
        return rejectedMsg;
      case RegistrationStatus.Completed:
      case ApplicationStatusOptions.ENROLLED:
        return enrolledMsg;
      default:
        return "";
    }
  };
  return (
    <>
      <CardPanel centre className="applicant-status__info-panel" testId="program-status-info-panel">
        <IonIcon icon={alertCircleOutline} />
        {infoMessage()}
      </CardPanel>
    </>
  );
};

export default ApplicantStatusBlock;
