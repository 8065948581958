type Params = Record<string, string | number | boolean | null | undefined>;

export const getSearchParamsStr = (params: Params) => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value === null || value === undefined) return;

    searchParams.set(key, value.toString());
  });

  return searchParams.toString();
};

export const urlify = (path: string, params?: Params): string => {
  const paramsStr = getSearchParamsStr(params || {});

  return [path, paramsStr].filter(Boolean).join("?");
};
