import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IonInput, IonNote } from "@ionic/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { NxuAlert, NxuComponentLoading, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import { useIdentityProfileInfo, useSubmitIdentifyInfo } from "utils/hooks/learner-identity";
import { DocumentItem, IdentityInfoData } from "types/learner-profile";
import { useRegistrationContext } from "utils/context/registration";
import { RequirementType } from "types/registrations";
import CardPanel from "components/atom/card-panel";
import IdentityContentBlock from "components/molecule/identity-content-block";
import IdentityDocumentForm from "components/molecule/identity-document-form";

import selfieExample from "assets/img/selfie_example.jpg";
import idExample from "assets/img/ID_example.jpg";
import idNigerianExample from "assets/img/ID_nigerian_example.png";

import "./identity-document-page.scss";

const identityFormSchema = yup.object().shape({
  applicantName: yup
    .string()
    .trim()
    .required("Full legal name is a required field")
    .max(200)
    .matches(
      // eslint-disable-next-line no-control-regex
      /^([A-Za-z\u0000-\u00ff\s]*)$/gi,
      "A full legal name using only Latin characters is required",
    ),
});

/**
 * Identity Documents Form for Degree Applicants
 */
const IdentityDocumentPage = () => {
  const { learnerProfileData, CompleteRequirements, token } = useRegistrationContext();

  const [idDocument, setIdDocument] = useState<DocumentItem>();
  const [personalPhoto, setPersonalPhoto] = useState<DocumentItem>();
  const [countryCode, setCountryCode] = useState<string>();
  const [uploadError, setUploadError] = useState<string>();

  const { data: identityProfileApiData, isFetching: identityProfileDataFetching } = useIdentityProfileInfo(token || "");

  const { mutate, isPending: submitInProgress, isSuccess } = useSubmitIdentifyInfo();

  const newIdentityForm = useForm({
    defaultValues: {
      applicantName: "",
    },
    resolver: yupResolver(identityFormSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = newIdentityForm;

  const cannotSubmitForm = !idDocument || !personalPhoto || isSuccess;

  const handleIdentityFormSubmit: SubmitHandler<any> = async (formValues) => {
    if (!isValid || !idDocument || !personalPhoto) return;
    setUploadError(undefined);
    const payload: IdentityInfoData = {
      officialName: formValues.applicantName,
      documents: [
        { id: "id", uploadedFileName: idDocument.uploadedFileName, originalFileName: idDocument.originalFileName },
        {
          id: "photo",
          uploadedFileName: personalPhoto.uploadedFileName,
          originalFileName: personalPhoto.originalFileName,
        },
      ],
    };

    mutate(payload, {
      onSuccess() {
        CompleteRequirements(() => Promise.resolve(), [RequirementType.GovIdentity, RequirementType.PhotoIdentity]);
      },
      onError() {
        setUploadError(
          "Uh-oh! There was an error on proceeding with your application. Please refresh and try again or contact support.",
        );
      },
    });
  };

  useEffect(() => {
    if (learnerProfileData) setCountryCode(learnerProfileData.Country);
  }, [learnerProfileData]);

  useEffect(() => {
    if (identityProfileApiData) {
      if (identityProfileApiData.officialName) {
        setValue("applicantName", identityProfileApiData?.officialName, {
          shouldValidate: true,
        });
      }
      if (
        identityProfileApiData?.idDocument?.uploadedFileName &&
        identityProfileApiData?.idDocument?.status !== "Failed"
      ) {
        setIdDocument({ ...identityProfileApiData.idDocument });
      }
      if (
        identityProfileApiData?.personalPhoto?.uploadedFileName &&
        identityProfileApiData?.personalPhoto?.status !== "Failed"
      ) {
        setPersonalPhoto({ ...identityProfileApiData.personalPhoto });
      }
    }
  }, [identityProfileApiData]);

  if (identityProfileDataFetching) {
    return <NxuComponentLoading />;
  }

  return (
    <CardPanel testId="identity-document-page" className="identity-document-page">
      <IdentityContentBlock countryCode={countryCode} />
      <form
        className="application-form application-form--identity"
        onSubmit={handleSubmit(handleIdentityFormSubmit)}
        data-testid="application-form--identity"
      >
        <Controller
          control={control}
          name="applicantName"
          render={({ field, fieldState }) => (
            <>
              <IonInput
                fill="outline"
                disabled={submitInProgress || isSuccess}
                onInput={field.onChange}
                onIonBlur={field.onBlur}
                type="text"
                placeholder="Enter your full legal name"
                aria-label="Full legal name"
                errorText={fieldState.error?.message}
                className={fieldState.error ? "ion-touched ion-invalid" : ""}
                value={field.value}
              />
              {!field.value && identityProfileApiData?.idDocument?.status === "Failed" && (
                <div className="identity-document__field-row identity-document__field-row--comment">
                  <IonNote color="danger">{`Your official name was rejected. ${identityProfileApiData?.idDocument?.comment}`}</IonNote>
                </div>
              )}
            </>
          )}
        />
      </form>
      <IdentityDocumentForm
        setUploadResponse={setIdDocument}
        exampleImg={countryCode === "NG" ? idNigerianExample : idExample}
        id="idDocument"
        placeholder="Upload your government-issued ID"
        friendlyName="Government-issued ID"
        inProgress={submitInProgress}
        existingOriginalFileName={identityProfileApiData?.idDocument?.originalFileName}
        currentDocument={identityProfileApiData?.idDocument}
      />
      <IdentityDocumentForm
        setUploadResponse={setPersonalPhoto}
        exampleImg={selfieExample}
        id="personalPhoto"
        placeholder="Upload a photo of yourself"
        friendlyName="Photo of yourself"
        inProgress={submitInProgress}
        existingOriginalFileName={identityProfileApiData?.personalPhoto?.originalFileName}
        currentDocument={identityProfileApiData?.personalPhoto}
      />
      {uploadError && <NxuAlert message={uploadError} />}
      <NxuPrimaryButton
        onClick={handleSubmit(handleIdentityFormSubmit)}
        disabled={submitInProgress || cannotSubmitForm}
        className="identity-document__form-submit"
        type="submit"
      >
        Save documents
      </NxuPrimaryButton>
    </CardPanel>
  );
};

export default IdentityDocumentPage;
