import React from "react";
import { useNavigate } from "react-router-dom";

import { LocalRoutes } from "constants/routes";
import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import GetInTouch from "components/atom/get-in-touch";
import { PageContent } from "components/molecule/page-wrap/page-wrap";

import "./error-pages.scss";

/**
 * Standard 404 page
 */
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <PageContent>
      <CardPanel className="error-page">
        <h1>This page you're looking for doesn't exist on ApplyNXU</h1>
        <p>Sorry for any inconvenience, please get in touch if you were directed to go to this page.</p>
        <NxuPrimaryButton onClick={() => navigate(LocalRoutes.HOME)}>Go to home</NxuPrimaryButton>
      </CardPanel>
      <GetInTouch />
    </PageContent>
  );
};

export default NotFound;
