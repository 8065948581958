// @ts-ignore
import React, { useEffect, useState } from "react";
import { IonNote } from "@ionic/react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent, StripeCardElement } from "@stripe/stripe-js";

import { PaymentFormInputs } from "types/payments";
import { mapStripePaymentData } from "utils/checkout-utils";

import { NxuAlert, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";

import CardholderDetails from "../cardholder-details";

import "./stripe-payment-form.scss";

// Payment Form validation schema
const paymentFormSchema = yup.object({
  FirstName: yup.string().required("First name is a required field").max(40),
  LastName: yup.string().required("Last name is a required field").max(80),
  Email: yup.string().email("Invalid email format").required(),
  Street: yup.string().required().max(150),
  City: yup.string().required().max(150),
  PostalCode: yup.string().required("Post/Zip code is a required field").max(50),
});

interface StripePaymentFormProps {
  onPaymentMethodCreated: (paymentMethodId: string) => void;
  handleStripePaymentCreate: (inProgress: boolean) => void;
  fullReset: () => void;
  submitting?: boolean;
  paymentError: string | undefined;
  pollingDelay?: boolean;
}

/**
 * Form component generating a Stripe payment method
 */
const StripePaymentForm = (props: StripePaymentFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const { onPaymentMethodCreated, handleStripePaymentCreate, fullReset, pollingDelay } = props;

  const [cardDisabled, setCardDisabled] = useState(true);
  const [stripeError, setStripeError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsSubmitting(!!props.submitting);
  }, [props.submitting, setIsSubmitting]);

  const newPaymentForm = useForm({
    defaultValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      Street: "",
      City: "",
      PostalCode: "",
    },
    resolver: yupResolver(paymentFormSchema),
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = newPaymentForm;

  const handlePaymentSubmit: SubmitHandler<PaymentFormInputs> = async (formValues) => {
    // Function shouldn't be reachable without Stripe and valid form values, but adding an escape just in case
    if (!stripe || !elements || stripeError || !isValid || isSubmitting) return;
    if (elements.getElement(CardElement) === null) return;
    fullReset();

    setIsSubmitting(true);
    handleStripePaymentCreate(true);
    // Generate billing details for stripe from the form values
    const billingDetails = mapStripePaymentData(formValues);

    const paymentMethodResponse = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement) as StripeCardElement,
      billing_details: billingDetails,
    });

    if (!paymentMethodResponse.paymentMethod || paymentMethodResponse.error) {
      setStripeError(
        paymentMethodResponse.error?.message || "Error creating payment. Please check your connection and try again.",
      );
      setIsSubmitting(false);
      handleStripePaymentCreate(false);
      return;
    }

    // Successfully created a payment method from the form, now pass the result up to the parent component
    onPaymentMethodCreated(paymentMethodResponse.paymentMethod.id);
  };

  const handleCardChange = async (event: StripeCardElementChangeEvent) => {
    setCardDisabled(event.empty);
    setStripeError(event.error ? event.error.message : "");
  };

  return (
    <form
      className="stripe-card__form"
      onSubmit={handleSubmit(handlePaymentSubmit)}
      data-testid="stripe-payment-details-form"
    >
      <>
        <CardholderDetails paymentForm={newPaymentForm} isSubmitting={isSubmitting} />
        <div className="stripe-card__fields" data-testid="stripe-card-fields">
          <CardElement
            options={{
              hidePostalCode: true,
              disabled: isSubmitting,
              style: { base: { fontFamily: "Roboto, sans-serif" } },
            }}
            onChange={handleCardChange}
          />
        </div>
        {!!stripeError && (
          <div className="stripe-card__error">
            <IonNote color="danger">{stripeError}</IonNote>
          </div>
        )}
        {!!props.paymentError && (
          <div className="stripe-card__error">
            <NxuAlert fullWidth message={props.paymentError} />
          </div>
        )}
        <NxuPrimaryButton
          type="submit"
          expand="block"
          className="stripe-card__action"
          disabled={isSubmitting || cardDisabled}
        >
          {isSubmitting ? "Payment in progress" : "Pay Now"}
        </NxuPrimaryButton>
      </>

      {isSubmitting && !pollingDelay && (
        <NxuAlert
          type="progress"
          message="Your payment is in progress. Please do not refresh or leave the current page."
        />
      )}
    </form>
  );
};

export default StripePaymentForm;
