import React from "react";
import cx from "classnames";
import "./alert-card-panel.scss";
import { informationCircle } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import CardPanel from "../card-panel";

export interface AlertCardPanelProps {
  title: string;
  status: "success";
  message: string;
  noBorder?: boolean;
  testId?: string;
  children?: React.ReactNode;
}

const AlertCardPanel = ({ title, status, message, noBorder, testId, children }: AlertCardPanelProps) => (
  <CardPanel
    className={cx("alert-card-panel", { [`alert-card-panel--no-border`]: noBorder })}
    data-testid={testId || "card-panel"}
    centre
  >
    <div className="alert-card-panel__content">
      <h5 className={cx("alert-card-panel__title", `alert-card-panel__title--${status}`)}>
        <IonIcon className="alert-card-panel__icon" icon={informationCircle} size="large" />
        {title}
      </h5>

      <p className="alert-card-panel__message">{message}</p>

      {children}
    </div>
  </CardPanel>
);

export default AlertCardPanel;
