import { useQuery } from "@tanstack/react-query";
import { apiGet } from "./api-client";

const fetchStartDateOptions = async (productType?: string, productCode?: string): Promise<Array<string>> =>
  apiGet(`/api/product-availability-api/start-date/${productType}/${productCode}/options`);

const useStartDateOptions = (productType?: string | null, productCode?: string | null) =>
  useQuery({
    queryKey: ["start-date-options", productType, productCode],
    queryFn: () => fetchStartDateOptions(productType || "", productCode || ""),
    throwOnError: false,
    enabled: !!productType && !!productCode,
    staleTime: Infinity,
  });

export { useStartDateOptions };
