import React from "react";
import CardPanel from "components/atom/card-panel";
import GetInTouch from "components/atom/get-in-touch";

import "./complete.scss";

/**
 * ApplyNXU Application Completed
 */
const CompletePage = () => (
  <CardPanel centre className="complete-page__info-panel" testId="complete-page-info-panel">
    <p>
      A learner account is already linked to your email address. You can view your program and learner profile on{" "}
      <a href={process.env.REACT_APP_MYNXU_URL}>myNXU</a>.
    </p>
    <GetInTouch type="admission">
      If you have not previously enrolled, or would like to change programs, please contact us
    </GetInTouch>
  </CardPanel>
);

export default CompletePage;
