import React from "react";

import "./identity-content-block.scss";

export interface IdentityContentBlockProps {
  countryCode?: string;
}

/**
 * Dynamic content for Identity Documents Page
 */
const IdentityContentBlock = ({ countryCode }: IdentityContentBlockProps) => {
  // Default text for degree applicants
  const standardHelpInfo = (
    <>
      <p data-testid="global-help-info">
        Upload your government-issued ID <strong>and</strong> a passport-style photo of you. It can be a selfie, but
        must be head and shoulders with your face visible. If we don’t get both, we can’t review your application.
      </p>
      <p>
        Your ID needs to be an official government-issued one (not a school ID!) and include a photo of you. Easiest is
        to take a picture of your ID using your mobile phone, then make sure it’s readable before uploading. If the ID
        is not in English, use your passport.
      </p>
    </>
  );

  // Text for degree applicants in Nigeria
  const nigerianHelpInfo = (
    <>
      <p data-testid="nigerian-help-info">
        1. Upload your <strong>government-issued ID</strong>. It must be readable (not blurry) and include your date of
        birth.
      </p>
      <ul className="documents-list documents-list--accepted">
        <li>Accepted Documents &#9989;</li>
        <li>Virtual NIMC ID</li>
        <li>Passport</li>
        <li>Driver's License</li>
        <li>Birth Certificate (with photo)</li>
        <li>Voter's ID</li>
      </ul>
      <ul className="documents-list documents-list--rejected">
        <li>Rejected Documents &#10060;</li>
        <li>NiN slip</li>
      </ul>
      <p>
        2. Upload a <strong>passport-style photo</strong> of your face.
      </p>
    </>
  );

  const infoMessage = () => {
    switch (countryCode) {
      case "NG":
        return nigerianHelpInfo;
      default:
        return standardHelpInfo;
    }
  };
  return (
    <div className="identity-page__content-panel" data-testid="identity-page__content-panel">
      <h2>Proof of Identity</h2>
      {infoMessage()}
    </div>
  );
};

export default IdentityContentBlock;
