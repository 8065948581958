import * as yup from "yup";

export const getCodeVerificationFormSchema = (enrollmentCodeFlow: boolean, hasPartner: boolean) =>
  yup.object({
    Email: yup
      .string()
      .trim()
      .when((_, schema) => {
        if (!enrollmentCodeFlow) {
          return schema.required("Email is a required field");
        }

        return schema;
      }),
    Code: yup
      .string()
      .trim()
      .when((_, schema) => {
        if (!enrollmentCodeFlow) {
          return schema.required("Verification Code is a required field");
        }

        return schema;
      }),
    enrollmentCode: yup.string().when((_, schema) => {
      if (hasPartner) {
        return schema.required("Enrollment Code is a required field");
      }

      return schema;
    }),
  });

export type CodeVerificationFormValues = yup.InferType<ReturnType<typeof getCodeVerificationFormSchema>>;
