import { useMutation, useQuery } from "@tanstack/react-query";
import { ProgramRegistrationPayload, ProgramRegistrationResponse, RegistrationResponse } from "types/registrations";
import { apiGet, apiPost } from "./api-client";

const fetchRegistrations = async (
  productType: string | null,
  productCode: string | null,
  partnerId: string | null,
): Promise<RegistrationResponse> => {
  const params = new URLSearchParams();
  params.append("productType", productType || "");
  params.append("productCode", productCode || "");
  if (partnerId) params.append("partnerId", partnerId);

  return apiGet(`/api/applynxuaggregator/self/registrations/latest?${params.toString()}`);
};

const useRegistration = (
  enabled: boolean,
  token: string | null,
  productType: string | null,
  productCode: string | null,
  partnerId: string | null,
  refetchMatch: (data?: RegistrationResponse) => boolean,
) =>
  useQuery({
    queryKey: ["registration", token, productType, productCode, partnerId],
    queryFn: (): Promise<RegistrationResponse> => fetchRegistrations(productType, productCode, partnerId),
    enabled,
    refetchOnWindowFocus: false,
    refetchInterval: (query) => {
      if (query.state.dataUpdateCount > 60) return false;
      return !refetchMatch(query.state.data) && 3000;
    },
  });

const useStartRegistration = (
  onSuccess?: (resp: ProgramRegistrationResponse) => void,
  onError?: (error: Error) => void,
) =>
  useMutation({
    mutationFn: (payload: ProgramRegistrationPayload): Promise<ProgramRegistrationResponse> =>
      apiPost("/api/applynxuaggregator/self/register", payload),
    onSuccess,
    onError,
  });

export { useRegistration, useStartRegistration };
