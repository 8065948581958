import React, { useEffect } from "react";

import { NxuComponentLoading } from "@nexford/nexford-ui-component-library";

import { useRegistrationContext } from "utils/context/registration";
import { RegistrationResponse, RequirementStatus, RequirementType } from "types/registrations";
import { PaymentsTypeOptions, PaymentTypes } from "types/payments";
/**
 * Partner daliy
 */

export interface CheckPartnerAgreementPageProps {
  paymentType: PaymentTypes;
}

const CheckPartnerAgreement: React.FC<CheckPartnerAgreementPageProps> = ({ paymentType }) => {
  const requirementToWaitFor =
    paymentType == PaymentsTypeOptions.ApplicationFee
      ? RequirementType.CheckPartnerApplicationFeeAgreement
      : RequirementType.CheckPartnerTuitionFeeAgreement;

  const { registrationData, CompleteRequirements, RefreshRegistrationData } = useRegistrationContext();

  const stopPollingCheck = React.useCallback(
    (r?: RegistrationResponse) =>
      r?.registrationDto?.requirements.find((req) => req.requirement === requirementToWaitFor)?.status ===
      RequirementStatus.Fulfilled,
    [],
  );

  useEffect(() => {
    RefreshRegistrationData((r) => stopPollingCheck(r));
  }, []);

  useEffect(() => {
    if (
      registrationData &&
      registrationData.requirements.find((req) => req.requirement === requirementToWaitFor)?.status ===
        RequirementStatus.Fulfilled
    ) {
      CompleteRequirements(() => Promise.resolve(), [requirementToWaitFor]);
    }
  }, [registrationData]);

  return <NxuComponentLoading />;
};

export default CheckPartnerAgreement;
