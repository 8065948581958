import React, { useEffect, useState } from "react";

import { FlywireCallback, FlywireConfig } from "types/payments";
import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";

declare const window: any;

interface FlywireProps {
  onSubmit: (arg0: FlywireCallback) => void;
  onCancel: () => void;
  submitting?: boolean;
  config: FlywireConfig;
  callbackId: string;
}

/**
 * Payment interface component for Flywire
 */
export default function Flywire({ onSubmit, onCancel, submitting, config, callbackId }: FlywireProps) {
  const [paymentIsActive, setPaymentIsActive] = useState(false);

  // Configure and open the flywire checkout
  const handleFlywirePay = () => {
    setPaymentIsActive(true);

    const updateConfig = {
      ...config,
      callbackId,
      onInvalidInput(errors: any[]) {
        errors.forEach((error) => {
          console.error(error);
        });
      },
      onCancel() {
        setPaymentIsActive(false);
        onCancel();
      },
      onCompleteCallback(args: {
        reference: string;
        status: FlywireCallback["Status"];
        amount: number;
        paymentMethod: FlywireCallback["PaymentMethod"];
        _: string;
        sig: string;
      }) {
        const data: FlywireCallback = {
          Reference: args.reference,
          Status: args.status,
          Amount: args.amount,
          PaymentMethod: args.paymentMethod,
          Nonce: args._,
          Sig: args.sig,
        };

        onSubmit(data);
        setPaymentIsActive(false);
      },
    };
    const modal = window.FlywirePayment.initiate(updateConfig);
    modal.render();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.flywire.com/flywire-payment.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <NxuPrimaryButton disabled={submitting || paymentIsActive} onClick={handleFlywirePay}>
      {submitting || paymentIsActive ? "In progress" : "Continue"}
    </NxuPrimaryButton>
  );
}
