import { Input, InputProps } from "components/atom/input/input";
import { FieldValues } from "react-hook-form";

interface Props<T extends FieldValues> extends InputProps<T> {
  partnerName?: string;
  partnerLogoUrl?: string;
}

export function EnrollmentCodeInput<T extends FieldValues>({ partnerName, partnerLogoUrl, ...props }: Props<T>) {
  if (!partnerName) {
    return null;
  }

  return (
    <div className="enrollment-code-input__container">
      <div className="enrollment-code-input__header">
        <h4 className="code-verification-form__title enrollment-code-input__title">
          It looks like you're starting your application through our partner: {partnerName}
        </h4>
        <p className="enrollment-code-input__description">
          To begin your journey towards a world-class education, please enter your unique Enrollment Code below. This
          will connect your application and ensure you receive all the benefits of this partnership.
        </p>
      </div>

      <div className="enrollment-code-input__field-container">
        {partnerLogoUrl && (
          <img src={partnerLogoUrl} alt={`${partnerName} logo`} className="enrollment-code-input__partner-logo" />
        )}
        <Input data-testid="enrollment-code-input" fill="outline" placeholder="Enrollment Code" {...props} />
      </div>
    </div>
  );
}
