import React from "react";
import "./returning-applicant.scss";
import { NxuAlert } from "@nexford/nexford-ui-component-library";

export interface ReturningApplicantProps {
  showAlert?: boolean;
}

// Title & alert block to display to unverified users with no product in the url
const ReturningApplicant = ({ showAlert = false }: ReturningApplicantProps) => (
  <div className="returning-applicant">
    <h2>Returning applicant? Pick up where you left off</h2>
    {showAlert && <NxuAlert message="We can't find an existing application with this email." fullWidth />}
  </div>
);

export default ReturningApplicant;
